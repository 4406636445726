import React from 'react';
import { connect } from 'react-redux'
import { setSearchParams } from "../../api/cars/actions";

let trans = [
    gettext("USB tengi"),
    gettext("Álfelgur"),
    gettext("Bakkmyndavél"),
    gettext("Bakkskynjari"),
    gettext("Bluetooth"),
    gettext("Cruise Control"),
    gettext("Dráttarkúla"),
    gettext("Samlæsing"),
    gettext("Hiti í stýri"),
    gettext("Hiti í sætum"),
    gettext("Isofix"),
    gettext("Leðursæti"),
    gettext("GPS"),
    gettext("Loftkæling"),
    gettext("Rafdrifnar rúður"),
    gettext("Sóllúga"),
    gettext("Nýr bíll"),
    gettext("Sjálfskiptur"),
    gettext("Dráttarbeisli"),
    gettext("Fjórhjóladrifinn"),
    gettext("Bensín"),
    gettext("Dísel"),
    gettext("ISOFIX"),
    gettext("Topplúga / Glerþak"),
    gettext("Leiðsögukerfi"),
    gettext("Loftkæling A/C"),
    gettext("Fjarstýrðar samlæsingar"),
    gettext("1 taska"),
    gettext("2 töskur"),
    gettext("3 töskur"),
    gettext("4 töskur"),
    gettext("5 töskur"),
    gettext("3 dyra"),
    gettext("4 dyra"),
    gettext("5 dyra"),
    gettext("7 dyra"),
]

class MultiSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredItems: this.props.searchItems,
            selectedItems: [],
        }
    }

    componentDidMount() {
        $('.form-group').find("select.multi").select2({
			allowHtml: true,
            allowClear: true,
            closeOnSelect: false,
            placeholder: gettext('Veldu'),
            allowClear: true,
        });
        let that = this;

        $('select.multi').on('select2:close', function (e) {
            let values = $('.multi').val();
            var uldiv = $(this).siblings('span.select2').find('ul')
            var count = $(this).select2('data').length
            if(count==0){
                uldiv.html(gettext('Veldu'))
            } else {
                uldiv.html("<li>"+count+' '+gettext('aukahlutir valdir')+"</li>")
            }
            that.setState({
                selectedItems: values
            })
        });

        $('select.multi').on('select2:select', function (e) {
            let values = $('.multi').val();
            Promise.resolve(that.props.store.dispatch(setSearchParams({ key: 'extras', value: values })))
            that.setState({
                selectedItems: values
            })
            var uldiv = $(this).siblings('span.select2').find('ul')
            var count = $(this).select2('data').length
            if(count==0){
                uldiv.html(gettext('Veldu'))
            } else {
                uldiv.html("<li>"+count+' '+gettext('aukahlutir valdir')+"</li>")
            }
        });

        $('select.multi').on('select2:unselect', function (e) {
            var uldiv = $(this).siblings('span.select2').find('ul')
            var count = $(this).select2('data').length
            if(count==0){
                uldiv.html(gettext('Veldu'))
            } else {
                uldiv.html("<li>"+count+' '+gettext('aukahlutir valdir')+"</li>")
            }
            let values = $('.multi').val();
            that.setState({
                selectedItems: values
            })
        });
        setTimeout(function() {
            $('input[type=search]').attr('disabled', 'disabled');
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.cars.searchParams.extras.length == 0 && this.state.selectedItems.length ==  0) {
            $('select.multi').val(null).trigger('change');
            var uldiv = $('select.multi').siblings('span.select2').find('ul');
            uldiv.html(gettext('Veldu'));
            // $('select.multi').select2('destroy');
            $('.form-group').find("select.multi").select2({
                allowHtml: true,
                allowClear: true,
                closeOnSelect: false,
                placeholder: gettext('Veldu'),
                allowClear: true,
            });
        }
        if (JSON.stringify(prevState.selectedItems) == JSON.stringify(this.state.selectedItems) && this.props.cars.searchParams.extras.length == 0) {
            $('.multi-mobile').val([]);
        }
    }

    filterSearchItems(e) {
        let itemFilter = e.target.value;
        let filteredItems = this.props.searchItems;
        filteredItems = filteredItems.filter((item) => {
            return item.toLowerCase().startsWith(itemFilter.toLowerCase())
        })
        this.props.activateDropdown(this.props.type);
        this.setState({
            filteredItems,
        })
    }

    suggestionSelected(e, from) {
        let values = $('.multi-mobile').val();

        Promise.resolve(this.props.store.dispatch(setSearchParams({ key: 'extras', value: values })))
        this.setState({
            selectedItems: values
        })
    }

    renderSuggestions() {
        let items = this.state.filteredItems;
        if (items) {
            if (items.length === 0) {
                return null;
            }
            return (
                items.map((item, key) => <option data-badge="" value={item} key={key} className="multiselect">{gettext(item)}</option>)
            )
        }
    }

    render() {
        return (
            <div className="autocomplete-text">
                <div className="input-wrapper d-lg-none">
                    <select className="multi-mobile" multiple="multiple" onChange={(e) => this.suggestionSelected(e, "select")} >
                        {this.renderSuggestions()}
                    </select>
                </div>
                <div className="input-wrapper form-group multiselect d-none d-lg-block">
                    <select className="multi" multiple="multiple">
                        {this.renderSuggestions()}
                    </select>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars;
    return f;
}

export default connect(mapStateToProps)(MultiSelect);