import React from 'react';
import { currencyFormatter } from '../../utils';

class Step1 extends React.Component {

    constructor(props) {
        super(props)
    }

    getCorrectBasePrice(location) {
        if (location.contractType === "winter") {
            return (
                <div className="price">{currencyFormatter(location.car.price_winter)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "12months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_12m)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "24months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_24m)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "36months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_36m)}<span> {gettext('kr./mán.')}</span></div>
            )
        }
    }

    getCorrectGoldPrice(location, gold_price) {
        if (location.contractType === "winter") {
            return (
                <div className="price">{currencyFormatter(location.car.price_winter + gold_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "12months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_12m + gold_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "24months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_24m + gold_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "36months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_36m + gold_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        }
    }

    getCorrectPlatinumPrice(location, platinum_price) {
        if (location.contractType === "winter") {
            return (
                <div className="price">{currencyFormatter(location.car.price_winter + platinum_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "12months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_12m + platinum_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "24months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_24m + platinum_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        } else if (location.contractType === "36months") {
            return (
                <div className="price">{currencyFormatter(location.car.price_36m + platinum_price)}<span> {gettext('kr./mán.')}</span></div>
            )
        }
    }

    onContractChoose(val) {
        this.props.onContractChoose(val);
        this.props.nextStep();
    }

    render() {
        let car = this.props.location.car;
        if (this.props.currentStep !== 1) {
            return null
        }
        return (
            <div className="container chooser-container">
                <div className="checkboxes-wrapper row m-0">
                    <h3 className="col-12">{gettext('Veldu áskriftarleið')}</h3>
                    <div className="col-12 price-checkbox-wrapper silver" onClick={this.onContractChoose.bind(this, "silver")}>
                        <div className="row">
                            <div className="col-2 col-lg-3 subscription-name">
                                <div className="subscription-wrapper">
                                    <h4>{gettext('Silfur')}</h4>
                                    <div>{gettext('áskrift')}</div>
                                </div>
                            </div>
                            <div className="col-10 col-lg-6 subscription-info">
                                <div><span>{currencyFormatter(car.personal_responsibility_silver)} kr.</span> {gettext('sjálfsábyrgð')}</div>
                                <div className="d-lg-none">{this.getCorrectBasePrice(this.props.location)}</div>
                            </div>
                            <div className="col-3 d-none d-lg-flex">
                                {this.getCorrectBasePrice(this.props.location)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 price-checkbox-wrapper gold" onClick={this.onContractChoose.bind(this, "gold")}>
                        <div className="row">
                            <div className="col-2 col-lg-3 subscription-name">
                                <div className="subscription-wrapper">
                                    <h4>{gettext('Gull')}</h4>
                                    <div>{gettext('áskrift')}</div>
                                </div>
                            </div>
                            <div className="col-10 col-lg-6 subscription-info">
                                <div><span>{currencyFormatter(car.personal_responsibility_gold)} kr.</span> {gettext('sjálfsábyrgð')}</div>
                                <div className="d-lg-none">{this.getCorrectGoldPrice(this.props.location, car.price_gold_packet)}</div>
                            </div>
                            <div className="col-3 d-none d-lg-flex">
                                {this.getCorrectGoldPrice(this.props.location, car.price_gold_packet)}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 price-checkbox-wrapper platinum" onClick={this.onContractChoose.bind(this, "platinum")}>
                        <div className="row">
                            <div className="col-2 col-lg-3 subscription-name">
                                <div className="subscription-wrapper">
                                    <h4>{gettext('Platinum')}</h4>
                                    <div>{gettext('áskrift')}</div>
                                </div>
                            </div>
                            <div className="col-10 col-lg-6 subscription-info">
                                <div><span>{currencyFormatter(car.personal_responsibility_platinum)} kr.</span> {gettext('sjálfsábyrgð')}</div>
                                <div className="d-lg-none">{this.getCorrectPlatinumPrice(this.props.location, car.price_platinum_packet)}</div>
                            </div>
                            <div className="col-3 d-none d-lg-flex">
                                {this.getCorrectPlatinumPrice(this.props.location, car.price_platinum_packet)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

Step1.defaultProps = {
}


export default Step1;