import React from 'react';

import { carTypes, SET_CHOSEN_CAR, CLEAR_CHOSEN_CAR, SAVE_PATHNAME, GET_LOWEST_PRICE, GET_MINMAX_PRICE, CLEAR_FILTERING, singleCarTypes, GET_CATEGORIES } from './actions'
import { getGasTypeName, getGasTypeValue } from '../../utils';



const initialState = {
    isFetching: false,
    searchParams: { cc: [], extras: [], gc: [] },
    results: [],
    makeSearchItems: [],
    modelSearchItems: [],
    gasTypes: [],
    extraList: [],
    chosenCar: {},
    lowestPrice: '',
}



export function cars(state = initialState, action) {
    switch (action.type) {

        case carTypes.SET_STATE:
            state = { ...state }
            if (action.data.value === "Allir framleiðendur" || action.data.value === "Allar gerðir" || action.data.value === "Ekkert valið") {
                action.data.value = ""
            }

            if (action.data.key === 'cc') {
                state.searchParams[action.data.key] = action.data.value;
                state.searchParams.extras = [];
                state.searchParams.gc = [];
                state.searchParams.car = "";
                state.searchParams.make = "";
                state.searchParams.model = "";
                state.searchParams.type = "";
                state.searchParams.gt = "";
            }

            if (action.data.key === 'make' || action.data.key === 'model') {
                state.searchParams.extras = [];
            }

            if (action.data.key === 'extras') {
                // let newObj = Object.values(state.searchParams.extras)
                // newObj = newObj.concat(action.data.value)
                state.searchParams.extras = action.data.value;
            } else if (action.data.key === 'gc' && !(state.searchParams.gc.indexOf(action.data.value) > -1)) {
                let newObj = Object.values(state.searchParams.gc)
                newObj = newObj.concat(action.data.value)
                state.searchParams.gc = newObj;
            } else if (action.data.key === 'gas_type') {
                state.searchParams[action.data.key] = getGasTypeValue(action.data.value);
            } else if (action.data.key !== 'extras') {
                state.searchParams[action.data.key] = action.data.value;
            }

            return state;

        case carTypes.REMOVE_FROM_STATE:
            state = { ...state }
            if (action.data.key === 'cc' && state.searchParams.cc.indexOf(action.data.value) > -1) {
                let newSearchCC = state.searchParams.cc.filter((param) => {
                    return param !== action.data.value
                })
                state.searchParams.cc = newSearchCC;
            } else if (action.data.key === 'extras' && state.searchParams.extras.indexOf(action.data.value) > -1) {
                let newSearchAccessories = state.searchParams.extras.filter((param) => {
                    return param !== action.data.value
                })
                state.searchParams.extras = newSearchAccessories;
            } else if (action.data.key === 'gc') {
                state.searchParams.gc = [];
            } else {
                delete state.searchParams[action.data.key]
            }
            return state;

        case carTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case carTypes.RECEIVE:
            state = { ...state, isFetching: false }
            if (action.data.length === undefined) {
                state.minMax = action.data
            } else {
                state.results = action.data
                if (state.results.length !== undefined) {
    
                    let carMakes = state.results.reduce((carsSoFar, { car_type_make }) => {
                        if (!carsSoFar[car_type_make]) carsSoFar[car_type_make] = [];
                        carsSoFar[car_type_make];
                        return carsSoFar;
                    }, {})
                    state.makeSearchItems = Object.keys(carMakes).map((carMake) => { return carMake })
    
                    let carTypes = state.results.reduce((carsSoFar, { car_type_model }) => {
                        if (!carsSoFar[car_type_model]) carsSoFar[car_type_model] = [];
                        carsSoFar[car_type_model];
                        return carsSoFar;
                    }, {})
                    state.modelSearchItems = Object.keys(carTypes).map((carType) => { return carType })
    
                    let gasTypes = state.results.reduce((carsSoFar, { gas_type }) => {
                        if (!carsSoFar[gas_type]) carsSoFar[gas_type] = [];
                        carsSoFar[gas_type];
                        return carsSoFar;
                    }, {})
                    state.gasTypes = Object.keys(gasTypes).map((gas_type) => { return getGasTypeName(gas_type) })
    
                    let accessories = []
                    state.results.map((car) => {
                        car.extras.map((extra) => {
                            if (accessories.indexOf(extra) <= -1) {
                                accessories.push(extra)
                            }
                        })
                    })
    
                    state.extraList = accessories;
                }   
            }

            return state
        
        case singleCarTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state
        
        case singleCarTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.chosenCar = action.data[0];
            return state

        case SET_CHOSEN_CAR:
            state = { ...state }
            state.chosenCar = action.data;
            return state

        case SAVE_PATHNAME:
            state = { ...state }
            state.lastPathname = action.data;
            return state

        case CLEAR_CHOSEN_CAR:
            state = { ...state }
            state.chosenCar = {};
            return state

        case GET_LOWEST_PRICE:
            state = { ...state, isFetching: false }
            state.lowestPrice = action.data

            return state

        case GET_CATEGORIES:
            state = { ...state, isFetching: false }
            state.categories = action.data

            return state

        case CLEAR_FILTERING:
            state = { ...state }
            state.searchParams.cc = [];
            state.searchParams.extras = [];
            state.searchParams.gc = [];
            state.searchParams.car = "";
            state.searchParams.make = "";
            state.searchParams.model = "";
            state.searchParams.type = "";
            state.searchParams.gt = "";
            state.makeSearchItems = [];
            state.modelSearchItems = [];
            state.results = [];

            return state;

        default:
            return state
    }
}