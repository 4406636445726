import React from "react";
import { currencyFormatter } from "../../utils";


class PriceBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            price: [this.props.cars.searchParams.pf ? this.props.cars.searchParams.pf : this.props.min, this.props.cars.searchParams.pt ? this.props.cars.searchParams.pt : this.props.defaultMax],
            defaultPrice : [this.props.min, this.props.defaultMax],
        }
    }


    onSliderChange(key1, key2, value) {
        this.setState({
            price: [value[0], value[1]]
        })
        this.props.onSliderChange(key1, key2, value)
    }
    
    render() {
        const Slider = require('rc-slider');
        const createSliderWithTooltip = Slider.createSliderWithTooltip;
        const Range = createSliderWithTooltip(Slider.Range);

        if ( this.props.cars.results.length === 0 || this.props.carChosen) {
            return (
                null
            )
        } else {
            const marks = {
                [this.props.min]: currencyFormatter(this.props.min),
                [this.props.tick1]: currencyFormatter((this.props.tick1).toFixed()),
                [this.props.tick2]: currencyFormatter((this.props.tick2).toFixed()),
                [this.props.max]: currencyFormatter(this.props.max),
            }
            return (
                <div className="rent-wrapper col-12 col-md-6 col-lg-4 order-xs-2">
                    <Range className="range" min={this.props.min} max={this.props.max} allowCross={false} defaultValue={this.state.price} tipProps={{ visible: true }} onAfterChange={this.onSliderChange.bind(this, 'pf', 'pt')} tipFormatter={value => `${currencyFormatter(value)} kr.`} marks={marks} dots={false}/>
                </div>
            )
        }

    }
}

PriceBar.defaultProps = {
}


export default PriceBar;