import React from 'react';
import Iframe from 'react-iframe';
import { connect } from 'react-redux'

class AppointmentIframe extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
       
    }



    render() {
        return (               
            <Iframe url="https://avisbilaleiga.youcanbook.me/?noframe=true&skipHeaderFooter=true"
                        width="100%"
                        id="ycbmiframeavisbilaleiga"
                        className="appointment-iframe"
                        display="initial"
                        position="relative"
                        allowFullScreen/>

        )
    }
}

AppointmentIframe.defaultProps = {
}


export default AppointmentIframe;