import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const specialCarTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'REMOVE_FROM_STATE', 'SET_STATE'], 'SPECIAL_CARS');

export const requestSpecialCars = () => {
    return {
        type: specialCarTypes.REQUEST,
    };
};

export const receiveSpecialCars = (response, query = {}) => {
    return {
        type: specialCarTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveSpecialCarsError = () => {
    return {
        type: specialCarTypes.ERROR,
    };
};

export const selectCar = (carObj) => {
    return {
        type: SET_CHOSEN_CAR,
        data: carObj
    }
}

export const removeCar = () => {
    return {
        type: CLEAR_CHOSEN_CAR,
    }
}

export const getLowestPrice = (response) => {
    return {
        type: GET_LOWEST_PRICE,
        data: response
    }
}

export const getLowestPriceError = () => {
    return {
        type: GET_LOWEST_PRICE_ERROR,
    }
}

export const clearCarFiltering = () => {
    return {
        type: CLEAR_FILTERING,
    }
}

export const fetchSpecialCars = () => {
    return dispatch => {
        dispatch(requestSpecialCars());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        return fetch(`/api/v3/specialcars/`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveSpecialCars(response));
                } catch (e) {
                    dispatch(receiveSpecialCarsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}