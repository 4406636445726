import React from 'react';
import AutoComplete from '../../components/autocomplete';
import MultiSelect from '../../components/multiselect'
import { Link } from 'react-router-dom';

class DetailedSearchModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            makeVal: this.props.cars.searchParams.make ? this.props.cars.searchParams.make : "",
            modelVal: this.props.cars.searchParams.model ? this.props.cars.searchParams.model : "",
        } 

    }

    activateDropdown() {
        this.props.activateDropdown();
    }

    search() {
        this.props.search();
        $('.modal-open').removeClass('modal-open');
        $('.modal-backdrop.fade.show').remove();
        $('#DetailedSearch').modal('toggle');
    }

    render() {
        if (this.props.searchParams.results.length === 0) { return (null) } else {
            return (
                <div className="modal aem d-lg-none" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true" id={this.props.id} data-backdrop="false">
                    <div className="modal-dialog">
                        <div className="modal-header">
                            <a className="navbar-brand" href="/">
                                <img src="/static/img/logo.svg" alt="Avis" />
                            </a>
                        </div>
                        <div className="modal-content">
                            <div className="modal-body car-chooser search-container">
                                <div className="row extensive-search">
                                    <h3 className="col-12 pb-4 title">{gettext('Ítarleg leit')}</h3>
                                    <button className="close" type="button" data-dismiss="modal" aria-hidden="true">&times;</button>
                                    <div className="all-dropdown-wrapper col-12">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Flokkur')}</h6>
                                                <AutoComplete
                                                    searchItems={this.props.searchParams.results.car_types}
                                                    store={this.props.store}
                                                    type="cc"
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    shouldFetchAllCars={false}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                            <div className="d-none d-sm-flex col-6"></div>
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Tegund')}</h6>
                                                <AutoComplete
                                                    searchItems={this.props.searchParams.results.car_makes}
                                                    store={this.props.store}
                                                    type="make"
                                                    makeVal={this.state.makeVal}
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    shouldFetchAllCars={false}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Gerð')}</h6>
                                                <AutoComplete
                                                    searchItems={this.props.searchParams.results.car_models}
                                                    store={this.props.store}
                                                    type="model"
                                                    modelVal={this.state.modelVal}
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    shouldFetchAllCars={false}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Gírskipting')}</h6>
                                                <AutoComplete
                                                    searchItems={this.props.searchParams.results.gears}
                                                    store={this.props.store}
                                                    type="gear"
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    shouldFetchAllCars={false}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Eldsneyti')}</h6>
                                                <AutoComplete
                                                    searchItems={this.props.searchParams.results.gas_types}
                                                    store={this.props.store}
                                                    type="gas_type"
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    shouldFetchAllCars={false}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                            <div className="col-12 col-sm-6 dropdown-col">
                                                <h6>{gettext('Aukahlutir')}</h6>
                                                <MultiSelect
                                                    searchItems={this.props.searchParams.results.extras}
                                                    store={this.props.store}
                                                    type="extras"
                                                    activateDropdown={this.activateDropdown.bind(this)}
                                                    updateSearchLists={this.props.updateSearchLists}
                                                    setSearchPath={this.props.setSearchPath}
                                                    fromHomepage={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {this.props.homepage ?
                                    <Link to={this.props.getPath()} className={this.props.getPath() === "" ? "search-btn disabled" : "search-btn"} onClick={this.search.bind(this)}><span>{gettext('Leita')}</span></Link> :
                                    <Link to={this.props.getPath()} className={this.props.getPath() === "" ? "search-btn disabled" : "search-btn"} onClick={this.search.bind(this)}><span>{gettext('Leita')}</span></Link>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}


DetailedSearchModal.defaultProps = {
}


export default DetailedSearchModal;