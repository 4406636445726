import $ from 'jquery';
window.$ = $;
window.jQuery = $;
import 'bootstrap';
require("@babel/polyfill");
import "isomorphic-fetch"

import Parsley from 'parsleyjs';
window.Parsley = Parsley;

import  Select2 from 'select2';
window.select2 = Select2;

require('./views/demo-container');
require('./views/homepage-container');
require('./views/loggedin-menu');
require('./views/order-car-container');

import "../../sass/main.scss"