import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchAllCars, setSearchParams, removeSearchParams, clearCarFiltering, selectCar, removeCar, savePathname, fetchSingleCar, fetchSpecialCars } from "../../api/cars/actions";
import { fetchMinMaxPrice } from '../../api/minmax/actions'
import { fetchUpsellCars } from '../../api/upsell/actions'
import { fetchAllSearchParams } from "../../api/searchParams/actions"

import { getSubCarGroups, getCarGroups, getCarGroupName, currencyFormatter, getPath } from "../../utils"

import ChosenCar from "../../components/chosen-car"
import SearchBar from "../../components/search-bar"
import PriceBar from "../../components/price-bar"
import Spinner from "../../components/spinner"
import UpsellCars from "../upsell-cars"
import DetailedSearchModal from '../detailed-search-modal';

class SubCarChooser extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            subCarGroups: [],
            groupName: "",
            navText: "",
            backHref: "/",
            directCarRef: false,
            searchOpen: true,
            searchPath: "",
            filtersHaveChanged: false,
            carsList: this.props.cars.results,
        }
        this.onChooseCar = this.onChooseCar.bind(this);
    }

    componentDidMount() {
        this.getCorrectInformationForPath();
        Promise.resolve(this.props.store.dispatch(fetchMinMaxPrice(this.props.cars.searchParams)))
        if ((!this.props.cars.carChosen || this.isEmpty(this.props.cars.chosenCar)) && window.location.hash !== "" && window.location.hash !== '#/') {
            let carNumber = window.location.hash.substring(1);
            if (carNumber.includes("/")) {
                carNumber = carNumber.replace("/", "");
            }
            Promise.resolve(this.props.store.dispatch(fetchSingleCar(carNumber, true)))
            .then(this.props.store.dispatch(fetchUpsellCars(carNumber, true)))
            .then(() => {
                this.setState({
                    navText: gettext('Aftur í lista'),
                })
                this.props.onChooseCar(true)
            }),
            this.setState({
                directCarRef: true,
                backHref: window.location.pathname,
            })
        } 
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname || (this.props.location.hash === "" && prevProps.location.hash !== "")) {
            this.getCorrectInformationForPath();
        }

        if (this.props.cars.results !== prevProps.cars.results) {
            this.setState({
                carsList: this.props.cars.results,
            })
        }

        if (prevProps.location.hash !== this.props.location.hash) {
            if (this.props.location.hash === "#/" || this.props.location.hash === "") {
                this.getCorrectInformationForPath();
            } else {
                let carNumber = this.props.location.hash.replace('#', "").replace('/', '')
                Promise.resolve(this.props.store.dispatch(fetchSingleCar(carNumber, true)))
                    .then(() => {
                        this.setState({
                            navText: gettext('Aftur í lista'),
                        })
                        this.props.onChooseCar(true)
                    })
            }
        }

        if(this.state.filtersHaveChanged === true) {
            $('body').addClass('car-list-faded');
        } else if (this.state.filtersHaveChanged === false) {
            $('body').removeClass('car-list-faded');
        }
    }

    getCorrectInformationForPath() {
        
        let groupName = "";
        this.props.onChooseCar(false);

        if (window.location.pathname.includes("/rafbilar")) {
            groupName = 'Rafbílar'
            if (!this.props.location.fromHomepageSearch) {
                this.chooseCarGroup(groupName)
            }
        } else if (window.location.pathname.includes("/jeppar")) {
            groupName = 'Jeppar'
            if (!this.props.location.fromHomepageSearch) {
                this.chooseCarGroup(groupName)
            }

        } else if (window.location.pathname.includes("/folksbilar")) {
            groupName = 'Fólksbílar'
            if (!this.props.location.fromHomepageSearch) {
                this.chooseCarGroup(groupName)
            }     
        } else if (window.location.pathname.includes("/serbilar")) {
            groupName = 'Sérbílar'
            if (!this.props.location.fromHomepageSearch) {
                this.chooseCarGroup(groupName)
            }     
        } else if (window.location.pathname.includes("/tilbodsbilar")) {
            groupName = 'Tilboðsbílar'
            if (!this.props.location.fromHomepageSearch) {
                this.chooseCarGroup(groupName)
            }        
        }
        
        this.setState({
            groupName: groupName,
            navText: groupName,
        })

        this.props.store.dispatch(setSearchParams({ key: 'cc', value: this.props.match.params.cc }))
    }

    onChooseCar(car) {
        Promise.resolve(this.props.store.dispatch(selectCar(car)))
            .then(() => {
                if (car) {
                    setTimeout(() => {
                        this.props.store.dispatch(fetchUpsellCars(car.car_number))
                    }, 500)
                }
            }
            )
        this.props.onChooseCar(true);
        this.setState({
            navText: gettext('Aftur í lista')
        })
    }

    chooseCarGroup(subCarGroup) {
        let carGroups = getCarGroups(subCarGroup)
        Promise.resolve(
            carGroups.map((carGroup) => {
                this.props.store.dispatch(setSearchParams({ key: 'gc', value: carGroup }))
            })
        )
        .then(() => {
            setTimeout(() => {
                this.props.store.dispatch(fetchAllSearchParams(this.props.cars.searchParams))
            }, 300)
        })
        .then(() => {
            setTimeout(() => {
                this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams))
            }, 100)
        })
        .then(this.props.store.dispatch(fetchMinMaxPrice(this.props.cars.searchParams)))
        .then(this.setState({
            navText: subCarGroup,
            backHref: window.location.pathname,
        }))
        .then(() => {
            setTimeout(() => {
                this.setState({
                    carsList: this.props.cars.results,
                })
            }, 500)
        })
    }

    fetchSearchParams(subCarGroup) {
        let carGroups = getCarGroups(subCarGroup)
        Promise.resolve(
            carGroups.map((carGroup) => {
                this.props.store.dispatch(setSearchParams({ key: 'gc', value: carGroup }))
            })
        )
            .then(() => {
                this.props.store.dispatch(fetchAllSearchParams(this.props.cars.searchParams))

            })
    }

    goBackHref() {
        let href = ""
        if (window.location.hash === "" || window.location.hash === "#/") {
            if (window.location.pathname.includes("borgarbilar") || window.location.pathname.includes("fjolskyldubilar") || window.location.pathname.includes("staerri-bilar")) {
                href = `/folksbilar`
            } else if (window.location.pathname.includes("jeppi") || window.location.pathname.includes("luxus-jeppar") || window.location.pathname.includes("jepplingar")) {
                href = `/jeppar`
            } else if (window.location.pathname === '/folksbilar' || window.location.pathname === '/jeppar' || window.location.pathname === '/sendibilar' || window.location.pathname === '/smarutur') {
                href = ``
            }
            return href
        }
    }

    goBack() {
        if (this.state.directCarRef || window.location.pathname.includes("search")) {
            this.props.dispatch(removeCar());
            this.props.dispatch(clearCarFiltering());
            this.props.onChooseCar(false);
            this.chooseCarGroup.bind(this, this.state.navText)
            this.setState({
                navText: this.state.groupName,
                backHref: "/",
            })
        } else if (this.state.backHref !== "/" && !this.isEmpty(this.props.cars.chosenCar)) {
            this.props.store.dispatch(removeCar());
            this.props.onChooseCar(false);
            this.setState({
                backHref: "ble"
            })
        } else if (this.state.backHref !== "/") {
            this.setState({
                navText: this.state.groupName,
                backHref: "/",
            })
        }
    }

    saveUrlToHistory() {
        this.props.store.dispatch(savePathname(window.location.pathname));
    }

    toggleExtras() {
        this.setState({
            showAllExtras: !this.state.showAllExtras,
        })
    }

    getExtras(extras) {
        if (extras) {
            let allExtras = extras.map((extra, i) => {
                if (this.state.showAllExtras) {
                    return (
                        <div className="col-4 extra">
                            {extra}
                        </div>
                    )
                } else {
                    if (i < 6) {
                        return (
                            <div className="col-4 extra">
                                {extra}
                            </div>
                        )
                    }
                }
            })
            return allExtras;
        }
    }

    isEmpty(obj) {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    }

    getGears(gear) {
        if (gear === 'man'){
            return gettext('Beinskiptur')
        } else {
            return gettext('Sjálfskiptur')
        }
    }

    getCorrectContent(cars) {
        if (this.props.carChosen) {
            return (<ChosenCar car={this.props.cars.chosenCar} cars={cars} upsellCars={this.props.upsellCars} store={this.props.store} onChooseCar={this.onChooseCar.bind(this)} carChosen={this.props.carChosen} />)
        } else if (cars.results.length === 0 && !cars.isFetching) {
            return (<div >{gettext('Enginn bíll uppfyllir leitarskilyrðin')}</div>)
        } else if (cars.results.length > 0) {
            return (<div className="row all-cars justify-content-center justify-content-md-start">{this.getAllCars(cars)}</div>)
        } else if (cars.isFetching){
            return (<Spinner/>)
        } else {
            return (<div>{gettext('Villa kom upp - vinsamlegast reynið aftur')}</div>)
        }
    }

    getCar(car, i) {
        let carGroupName = getCarGroupName(car.group_code);
        let pathname = getPath(carGroupName);
        return (
            <div className="card-wrapper col-12 col-sm-6 col-lg-4" key={i}>
                <Link className="card card-car" car={car} key={car.car_number} to={{ pathname: window.location.pathname === "/" ? pathname : window.location.pathname, hash: `${car.car_number}` }} onClick={this.onChooseCar.bind(this, car)}>
                    <div className="card-body">
                        <div className="card-information">
                            <div className="card-title">
                                <div className="group">{car.group_category_name}{car.show_vat_free_logo && " - verð án vsk út árið 2023"}</div>
                                <div>{car.car_type_verbose}</div>
                            </div>
                            <div className="precise-info row">
                                <div className="col-3">
                                    <div className="title">{gettext('Árgerð')}</div>
                                    <div>{`20` + car.model_year}</div>
                                </div>
                                <div className="col-4"> 
                                        <div className="title">{gettext('Skipting')}</div>
                                        <div>{this.getGears(car.gear)}</div>
                                </div>
                                <div className="col-5">
                                    <div className="title">{gettext("Verð frá")}</div>
                                    <div>{currencyFormatter(car.price_min)} {gettext('kr./mán.')}</div>
                                </div>
                            </div>
                            <div className="card-img-wrapper">
                                <img className="card-img img-fluid" src={car.card_image}></img>
                            </div>
                        </div>
                        <div className="card-bottom">
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    getAllCars(cars) {
        if (cars && this.state.carsList.length > 0) {
            let allCars = [].concat(this.state.carsList)
                .sort((a, b) => parseFloat(a.price_min) - parseFloat(b.price_min)).map((car, i) =>
                    this.getCar(car, i + 1)
                )
            return allCars;
        }
    }

    updateSearchParams() {
        this.props.store.dispatch(fetchAllSearchParams(this.props.cars.searchParams))
    }

    onSliderChange(key1, key2, value) {
        this.setState({
            carsList: this.props.cars.results.filter((car) => 
                car.price_min >= value[0] && car.price_min <= value[1]
            )
        })
    }

    search() {
        Promise.resolve(this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams)))
            .then(() => {
                setTimeout(() => {
                    this.setState({
                        carsList: this.props.cars.results,
                    })
                }, 500)
            })
    }

    toggleSearchOpen() {
        this.setState({
            searchOpen: !this.state.searchOpen
        })
    }

    setSearchPath(value) {
        this.setState({
            searchPath: value,
        })
    }

    getPath() {
        let typeChoice = this.state.searchPath;
        if (typeChoice === 'Fólksbílar') {
            return (`/folksbilar`)
        } else if (typeChoice === 'Jeppar') {
            return (`/jeppar`)
        } else if (typeChoice === 'Rafbílar') {
            return (`/rafbilar`)
        } else if (typeChoice === 'Sérbílar') {
            return (`/serbilar`)
        } else {
            return ''
        }
    }

    activateDropdown(type) {
        let state = { ...this.state }
        state[type] = !state[type]
        this.setState(state)
    }

    filtersHaveChanged(val){
        this.setState({
            filtersHaveChanged: val,
        })
    }

    updatePriceBar(){
        Promise.resolve(this.props.store.dispatch(fetchMinMaxPrice(this.props.cars.searchParams)))
    }

    render() {
        let cars = this.props.cars;
        let minmax = this.props.minmax;
        const min = minmax.minMax.min;
        const max = minmax.minMax.max;
        const rangeSize = (max - min) / 3;
        let tick1 = +min + rangeSize;
        let tick2 = +tick1 + rangeSize;
        let searchParams = this.props.searchParams;
        let defaultMax = cars.results.reduce((acc,car)=>{
            const carMax = Math.max(...cars.results.map(item => item.price_36m))
            return carMax > acc ? carMax : acc
        },0)

        return (
            <>
                {cars.isFetching  && <Spinner/>}
                <SearchBar
                    store={this.props.store}
                    search={this.search.bind(this)}
                    carChosen={this.props.carChosen}
                    toggleSearchOpen={this.toggleSearchOpen.bind(this)}
                    searchOpen={this.state.searchOpen}
                    setSearchPath={this.setSearchPath.bind(this)}
                    filtersHaveChanged={this.filtersHaveChanged.bind(this)}
                    updatePriceBar={this.updatePriceBar.bind(this)}
                />
                <div className={this.state.filtersHaveChanged ? "sub-car faded container" : "sub-car container"}>
                    <div className="row back-btn-search">
                        <div className="col-6">
                            <div className="row">
                                <Link to={this.goBackHref()} onClick={this.goBack.bind(this)} className="btn back-btn col-12">
                                    <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.8 12.8">
                                        <path fill="#D2072F" d="M4.8 0l.8.6-4 5.3h15.2v1H1.6l4 5.3-.8.6L0 6.4z" />
                                    </svg>
                                </Link>
                                {this.state.backHref === "/" ? <></> : <div className="col-12 nav-text">{gettext(this.state.navText)}</div>}
                                
                            </div>
                        </div>
                        <Link to={this.goBackHref()} onClick={this.goBack.bind(this)} className="btn back-btn d-none d-md-block col-md-6 col-lg-2">
                            {/* <svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.8 12.8">
                                <path fill="#D2072F" d="M4.8 0l.8.6-4 5.3h15.2v1H1.6l4 5.3-.8.6L0 6.4z" />
                            </svg> */}
                            <span>{this.state.navText}</span>
                        </Link>
                        <div className="d-none col-12 col-lg-8"></div>
                        {!this.props.minmax.isFetching && defaultMax !== 0 ?
                            <PriceBar
                                searchParams={searchParams}
                                onSliderChange={this.onSliderChange.bind(this)}
                                cars={this.props.cars}
                                carChosen={this.props.carChosen}
                                min={min}
                                max={max}
                                tick1={tick1}
                                tick2={tick2}
                                searchOpen={this.state.searchOpen}
                                defaultMax={defaultMax}
                            /> : null
                        }
                        {!this.props.carChosen ?
                            <div className="d-lg-none col-6 search-button align-self-sm-end">
                                <button className="btn btn-primary detailed-search-btn solid" data-toggle="modal" data-target={"#DetailedSearch"}>
                                    <span>{gettext("Ítarleg leit")}</span>
                                    <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20">
                                        <path fillRule="nonzero"
                                            d="M6 0C2.693 0 0 2.693 0 6c0 3.306 2.693 6 6 6a5.963 5.963 0 0 0 3.793-1.357l3.181 3.181a.602.602 0 0 0 .85 0 .594.594 0 0 0 0-.843l-3.18-3.182A5.989 5.989 0 0 0 11.998 6C12 2.694 9.306 0 6 0zm0 1c2.769 0 5 2.231 5 5s-2.231 5-5 5a4.99 4.99 0 0 1-5-5c0-2.769 2.231-5 5-5z" />
                                    </svg>
                                </button>
                            </div> : null}
                    </div>
                    {this.getCorrectContent(cars)}
                </div>
                {this.props.carChosen ? <UpsellCars upsellCars={this.props.upsellCars} onChooseCar={this.onChooseCar} store={this.props.store} /> : null}

                <DetailedSearchModal id="DetailedSearch"
                    cars={cars}
                    carType={this.state.carType}
                    activateDropdown={this.activateDropdown.bind(this)}
                    gearType={this.state.gearType}
                    store={this.props.store}
                    searchParams={searchParams}
                    setSearchPath={this.setSearchPath.bind(this)}
                    updateSearchLists={this.updateSearchParams.bind(this)}
                    search={this.search.bind(this)}
                    getPath={this.getPath.bind(this)}
                    homepage={false}
                    filtersHaveChanged={this.filtersHaveChanged.bind(this)}
                />

            </>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        members: {},
        cars: {},
        pages: {},
        login: {},
        searchParams: {},
        specialCars: {},
        upsellCars: {},
        minmax: {},
    }
    f.members = (state.members === undefined) ? {} : state.members
    f.cars = (state.cars === undefined) ? {} : state.cars
    f.pages = (state.pages === undefined) ? {} : state.pages
    f.login = (state.login === undefined) ? {} : state.login
    f.searchParams = (state.searchParams === undefined) ? {} : state.searchParams
    f.specialCars = (state.specialCars === undefined) ? {} : state.specialCars
    f.upsellCars = (state.upsellCars === undefined) ? {} : state.upsellCars
    f.minmax = (state.minmax === undefined) ? {} : state.minmax
    return f;
}

export default connect(mapStateToProps)(SubCarChooser);