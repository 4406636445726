import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { getCookie } from '../../utils'
export const loginTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'LOGIN');
export const saveVisitorTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SAVE_VISITOR');

export const requestLogin = () => {
    return {
        type: loginTypes.REQUEST,
    };
};

export const receiveLogin = (response, query = {}) => {
    return {
        type: loginTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveLoginError = () => {
    return {
        type: loginTypes.ERROR,
    };
};

export const requestSaveVisitor = () => {
    return {
        type: saveVisitorTypes.REQUEST,
    };
};

export const receiveSaveVisitor = (response, query = {}) => {
    return {
        type: saveVisitorTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveSaveVisitorError = () => {
    return {
        type: saveVisitorTypes.ERROR,
    };
};

export const saveVisitor = (car_object, customer_object, phone, email) => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie('csrftoken'),
        }
        dispatch(requestSaveVisitor());

        fetch(`/api/v3/savevisitorinfo/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'car_object': car_object, 'customer_object': customer_object, 'phone': phone, 'email': email}),
        })
            .then(response => response.json())
            .then(response => {
                 try {
                    dispatch(receiveSaveVisitor(response));
                } catch (e) {
                    dispatch(receiveSaveVisitorError(response))
                }
            })
            .catch(handleNetworkError);
    } 
}

export const memberLogin = (phoneno) => {
    return dispatch => {
        let headers = {
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestLogin());

        var formData = new FormData()
        formData.append('phoneno', phoneno)
        formData.append('no_redirect', true)

        fetch(window.login_url+`taktikalajax/`, {
            method: 'POST',
            headers: headers,
            body: formData,
        })
            .then(response => response.json())
            .then(response => {
                 try {
                     dispatch(receiveLogin(response));
                } catch (e) {
                    dispatch(receiveLoginError(response))
                }
            })
            .catch(handleNetworkError);
    }
}