import React from 'react';

import { loginTypes } from './actions'

const initialState = {
    isFetching: false,
    results: [],
}



export function login(state = initialState, action) {


    switch (action.type) {

        case loginTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state


        case loginTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = action.data

            return state

        default:
            return state
    }
}