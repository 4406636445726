import React from 'react';
import MyCarSlider from "../../components/my-car-slider"
import AppointmentIframe from "../../components/appointment-iframe"

import { currencyFormatter } from '../../utils';


class MyContracts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentContract: 0,
        }
    }


    updateCurrentSlide(currentSlide) {
        this.setState({
            currentContract: currentSlide,
        })
    }


    getContractInformation(contract) {
        let today = new Date();
        let validTo = new Date(contract.valid_to);
        let firstDayOfContract = new Date(contract.upphafsdagur_samnings).toLocaleDateString();
        let monthsLeft = (validTo - today) / 1000 / (60 * 60 * 24) / 30;

        return (
            <>
                <div className="km">
                    <span className="title">{gettext('Eknir km við upphaf leigu')}</span>
                    <span className="numbers">{currencyFormatter(contract.initial_km)} <span className="text">{gettext('km')}</span></span>
                </div>
                <div>
                    <span className="title">{gettext('Innifalinn akstur')}</span>
                    <span className="numbers">{currencyFormatter(contract.km_included)} <span className="text">{gettext('km')}</span></span>
                </div>
                <div>
                    <span className="title">{gettext('Kostnaður vegna umframaksturs')}</span>
                    <span className="numbers">{currencyFormatter(contract.km_cost)} <span className="text">{gettext('kr. á km')}</span></span>
                </div>
                <div>
                    <span className="title">{gettext('Sjálfsábyrgð')}</span>
                    <span className="numbers">{currencyFormatter(contract.self_responsibility)} <span className="text">{gettext('kr.')}</span></span>
                </div>
                <div className="appointments">
                    <span className="title">{gettext('Tímabókanir')}</span>
                    <div className="workshop">
                        <a href={window.location.href + "#appointments"} target="_blank"><span>{gettext('Verkstæði')} / {gettext('Dekkjaþjónusta')}</span></a>
                    </div>
                </div>
            </>

        )
    }


    render() {
        let contracts = this.props.members;
        if (window.location.hash == "#appointments") {
            return (       
                <div className="my-pages container-fluid">
                    <div className="row appointments-iframe">
                            <AppointmentIframe/>
                    </div>
                </div>)
        }
        if (this.props.members.results.length > 0 && this.props.members.results[0].member) {
            return (
                <div className="my-pages container-fluid">
                    <div className="container">
                        <div className="col-12 my-contracts">
                            <div className="row">
                                <h2 className="col-12 pl-0 pr-0">{gettext('Mitt AVIS')}</h2>
                                <div className="col-11 col-sm-12 info-section">
                                    <div className="row">
                                        <MyCarSlider cars={contracts} updateCurrentSlide={this.updateCurrentSlide.bind(this)} />

                                        {contracts.results[this.state.currentContract] ?
                                            <div className="info-wrapper col-12 col-lg-4 pr-0">
                                                <div className="car-specific-info">
                                                    {this.getContractInformation(contracts.results[this.state.currentContract])}
                                                </div>
                                            </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            window.location.pathname = "/minarsidur/minar-upplysingar"
            return <div></div>
        }
    }
}

MyContracts.defaultProps = {
}


export default MyContracts;