import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const earlyPickupTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'EARLY_PICKUP');

export const requestEarlyPickup = () => {
    return {
        type: earlyPickupTypes.REQUEST,
    };
};

export const receiveEarlyPickup = (response, query = {}) => {
    return {
        type: earlyPickupTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveEarlyPickupError = () => {
    return {
        type: earlyPickupTypes.ERROR,
    };
};

export const earlyPickup = (contract_object, csrf_token) => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf_token
        }
        dispatch(requestEarlyPickup());

        fetch(`/api/v3/earlypickup/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'contract_object': contract_object}),
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveEarlyPickup(response));
                } catch (e) {
                    dispatch(receiveEarlyPickupError(response))
                }


            })
            .catch(handleNetworkError);
    }
}