import React from 'react';

import { searchParamsTypes } from './actions'
import { getGasTypeName } from '../../utils';

const initialState = {
    isFetching: false,
    results: [],
}



export function searchParams(state = initialState, action) {


    switch (action.type) {

        case searchParamsTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            
            return state


        case searchParamsTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.results = action.data
            state.results.gas_types = state.results.gas_types.map((item) => {return getGasTypeName(item)})

            return state

        default:
            return state
    }
}