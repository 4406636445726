import React from 'react';
import { currencyFormatter } from '../../utils';

class Step4 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            // bodgreidslurApprove: false,
            leiguskilmalarApprove: false,
            formvalid: true,
            isCardHolderValid: true,
            isValidUntilValid: true,
            isCardNumberValid: true,
            isCvcNumberValid: true,
        }
    }

    componentDidMount(){

    }
    
    componentDidUpdate() {
        addSelect2Library();
        let that = this;
        $('.gildistimi').find('select').on('select2:select', function (e) {
            that.props.handlePaymentInputChange(e, "select2");
        });
    }

    checkout() {
        if (this.validatePaymentForm()) {
            this.setState({
                formvalid: true,
            })
            this.props.checkout();
        } else {
            this.setState({
                formvalid: false,
            })
        }        
    }

    toggleApprove(e) {
        this.setState({
            [e.currentTarget.id]: !this.state[e.currentTarget.id]
        })
    }

    isFormValid(){
        let paymentInfo = this.props.paymentInformation;
        let nameIncludesSpace = /\s/.test(paymentInfo.cardHolder);

        if (paymentInfo.cvc.length < 3 || paymentInfo.validUntilMonth === "" || paymentInfo.validUntilYear === "" || paymentInfo.cardNumber === "" || paymentInfo.cardHolder === "" || !nameIncludesSpace || paymentInfo.cvc.length < 3) {
            return false
        } else {
            return true
        }
    }

    validatePaymentForm() {
        let paymentInfo = this.props.paymentInformation;
        let nameIncludesSpace = /\s/.test(paymentInfo.cardHolder);

        if (paymentInfo.cardHolder === "" || !nameIncludesSpace) {
            this.setState({
                isCardHolderValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCardHolderValid: true,
            })
        }

        if (paymentInfo.cardNumber === "") {
            this.setState({
                isCardNumberValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCardNumberValid: true,
            })
        }

        if (paymentInfo.validUntilMonth === "" || paymentInfo.validUntilYear === "") {
            this.setState({
                isValidUntilValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isValidUntilValid: true,
            })
        }

        if (paymentInfo.cvc.length < 3) {
            this.setState({
                isCvcNumberValid: false,
                formvalid: false,
            })
        } else {
            this.setState({
                isCvcNumberValid: true,
            })
        }
        if (this.isFormValid()){
            return true
        } else {
            return false
        }

    }

    render() {
        if (this.props.currentStep !== 4) {
            return null
        }
        return (
            <>
            <div className="container chooser-container">
                <div className="payment-step row">
                    <div className="col-12 title">{gettext('Greiðsla')}</div>
                    {this.props.pages.results.items && this.props.pages.results.items[0].card_charge_explanation !== "" && <div className="col-12" dangerouslySetInnerHTML={{ __html: this.props.pages.results.items[0].card_charge_explanation.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>}
                    <div className="form-group col-12">
                        <label className="col-form-label" htmlFor="cardHolder">{gettext('Korthafi')}</label>
                        <input
                            className="form-control"
                            id="cardHolder"
                            name="cardHolder"
                            type="text"
                            value={this.props.paymentInformation.cardHolder} // Prop: The kennitala input data
                            onChange={this.props.handlePaymentInputChange}
                            required // Prop: Puts data into state
                            autoFocus
                        />
                        {!this.state.isCardHolderValid ? <div className="validation-warning pl-0 col-12">{gettext('Slá þarf inn fullt nafn.')}</div> : null}
                    </div>
                    <div className="form-group col-12">
                        <label className="col-form-label" htmlFor="cardNumber">{gettext('Kortanúmer')}</label>
                        <input
                            className="form-control"
                            id="cardNumber"
                            name="cardNumber"
                            type="number"
                            value={this.props.paymentInformation.cardNumber} // Prop: The kennitala input data
                            onChange={this.props.handlePaymentInputChange}
                            required // Prop: Puts data into state
                        />
                        {!this.state.isCardNumberValid ? <div className="validation-warning pl-0 col-12">{gettext('Slá þarf inn gilt kortanúmer.')}</div> : null}
                    </div>
                    <div className="form-group d-none d-lg-block col-6 col-md-4 col-lg-12">
                        <label className="col-form-label" htmlFor="validUntilMonth">{gettext('Gildistími')}</label>
                        <div className="gildistimi">
                            <select className="form-control" id="validUntilMonth" name="validUntilMonth" onChange={this.props.handlePaymentInputChange} value={this.props.paymentInformation.validUntilMonth}>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                                <option>05</option>
                                <option>06</option>
                                <option>07</option>
                                <option>08</option>
                                <option>09</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </select>
                            {!this.state.isValidUntilValid ? <div className="validation-warning pl-0 col-12">{gettext('Velja þarf gildistíma korts.')}</div> : null}
                            <select className="form-control" id="validUntilYear" name="validUntilYear" onChange={this.props.handlePaymentInputChange} value={this.props.paymentInformation.validUntilYear}>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                                <option value="26">2026</option>
                                <option value="27">2027</option>
                                <option value="28">2028</option>
                                <option value="29">2029</option>
                                <option value="30">2030</option>
                                <option value="31">2031</option>
                            </select>
                        </div>
                    </div>
                    <div className="valid-until-month d-lg-none col-12 col-md-8 col-lg-5">
                        <label className="col-form-label" htmlFor="validUntilMonth">{gettext('Gildistími')}</label>
                        <div className="gildistimi">
                            <select className="form-control" id="validUntilMonth" name="validUntilMonth" onChange={this.props.handlePaymentInputChange} value={this.props.paymentInformation.validUntilMonth}>
                                <option>01</option>
                                <option>02</option>
                                <option>03</option>
                                <option>04</option>
                                <option>05</option>
                                <option>06</option>
                                <option>07</option>
                                <option>08</option>
                                <option>09</option>
                                <option>10</option>
                                <option>11</option>
                                <option>12</option>
                            </select>
                            {!this.state.isValidUntilValid ? <div className="validation-warning pl-0 col-12">{gettext('Velja þarf gildistíma korts.')}</div> : null}
                            <select className="form-control" id="validUntilYear" name="validUntilYear" onChange={this.props.handlePaymentInputChange} value={this.props.paymentInformation.validUntilYear}>
                                <option value="23">2023</option>
                                <option value="24">2024</option>
                                <option value="25">2025</option>
                                <option value="26">2026</option>
                                <option value="27">2027</option>
                                <option value="28">2028</option>
                                <option value="29">2029</option>
                                <option value="30">2030</option>
                                <option value="31">2031</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group col-6 col-md-4">
                        <label className="col-form-label" htmlFor="cvc">{gettext('Öryggisnúmer CVC')}</label>
                        <input
                            className="form-control"
                            id="cvc"
                            name="cvc"
                            type="number"
                            value={this.props.paymentInformation.cvc} // Prop: The kennitala input data
                            onChange={this.props.handlePaymentInputChange}
                            required // Prop: Puts data into state
                        />
                        {!this.state.isCvcNumberValid ? <div className="validation-warning pl-0 col-12">{gettext('CVC of stutt.')}</div> : null}
                    </div>
                    {/* <div className="col-12 approve-checkbox">
                        <input id="bodgreidslurApprove" type="checkbox" onChange={this.toggleApprove.bind(this)} /><span className="checkmark"></span><label htmlFor="bodgreidslurApprove">Ég samþykki <a href="/skilmalar-um-bodgreidslur" target="_blank">skilmála um boðgreiðslur.</a></label>
                    </div> */}
                    <div className="col-12 approve-checkbox">
                        <input id="leiguskilmalarApprove" type="checkbox" onChange={this.toggleApprove.bind(this)} /><span className="checkmark"></span><label htmlFor="leiguskilmalarApprove">{gettext('Ég samþykki')} <a href="/skilmalar" target="_blank">{gettext('leiguskilmála AVIS')}</a></label>
                    </div>
                    {this.props.responseMessage !== "" ? <div className="col-12 error-msg">{this.props.responseMessage}</div> : null }
                </div>
            </div>
            <div className={this.isFormValid() && this.state.leiguskilmalarApprove && this.props.paymentButtonEnabled ? "search-btn" : "search-btn disabled"} disabled={this.props.paymentButtonEnabled} onClick={this.checkout.bind(this)}><span>{this.props.paymentButtonText}</span></div>
            </>
        )
    }
}

Step4.defaultProps = {
}


export default Step4;