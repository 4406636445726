import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const carTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'REMOVE_FROM_STATE', 'SET_STATE'], 'CARS');
export const singleCarTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'REMOVE_FROM_STATE', 'SET_STATE'], 'SINGLE_CAR');
export const SET_CHOSEN_CAR = 'SET_CHOSEN_CAR';
export const CLEAR_CHOSEN_CAR = 'CLEAR_CHOSEN_CAR';
export const SAVE_PATHNAME = 'SAVE_PATHNAME';
export const GET_LOWEST_PRICE = 'GET_LOWEST_PRICE';
export const GET_LOWEST_PRICE_ERROR = 'GET_LOWEST_PRICE_ERROR';
export const CLEAR_FILTERING = 'CLEAR_FILTERING';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';


export const setSearchParams = (params) => {
    return {
        type: carTypes.SET_STATE,
        data: params
    }
}

export const removeSearchParams = (params) => {
    return {
        type: carTypes.REMOVE_FROM_STATE,
        data: params
    }
}

export const requestCars = () => {
    return {
        type: carTypes.REQUEST,
    };
};

export const receiveCars = (response, query = {}) => {
    return {
        type: carTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveCarsError = () => {
    return {
        type: carTypes.ERROR,
    };
};

export const requestSingleCar = () => {
    return {
        type: singleCarTypes.REQUEST,
    };
};

export const receiveSingleCar = (response, query = {}) => {
    return {
        type: singleCarTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveSingleCarError = () => {
    return {
        type: singleCarTypes.ERROR,
    };
};

export const selectCar = (carObj) => {
    return {
        type: SET_CHOSEN_CAR,
        data: carObj
    }
}

export const savePathname = (path) => {
    return {
        type: SAVE_PATHNAME,
        data: path,
    }
}

export const removeCar = () => {
    return {
        type: CLEAR_CHOSEN_CAR,
    }
}

export const getLowestPrice = (response) => {
    return {
        type: GET_LOWEST_PRICE,
        data: response
    }
}

export const getLowestPriceError = () => {
    return {
        type: GET_LOWEST_PRICE_ERROR,
    }
}

export const clearCarFiltering = () => {
    return {
        type: CLEAR_FILTERING,
    }
}

export const getCategories = (response) => {
    return {
        type: GET_CATEGORIES,
        data: response
    }
}

export const getCategoriesError = () => {
    return {
        type: GET_CATEGORIES_ERROR,
    }
}

export const fetchSpecialCars = () => {
    return dispatch => {
        dispatch(requestCars());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        return fetch(`/api/v3/specialcars/`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveCars(response));
                } catch (e) {
                    dispatch(receiveCarsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const fetchAllCars = (params = {}) => {

    return dispatch => {
        dispatch(requestCars());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        let newParamsObj = {};
        Object.keys(params).map(element => {
            if (element === "extras" || element === "gc") {
                newParamsObj[element] = params[element].join()
            } else {
                newParamsObj[element] = params[element]
            }
        });

        return fetch(`/api/v3/cars/?${$.param(newParamsObj)}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveCars(response));
                } catch (e) {
                    dispatch(receiveCarsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const fetchSingleCar = (carNumber, withReserved) => {

    return dispatch => {
        dispatch(requestSingleCar());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        return fetch(`/api/v3/cars/?car=` + carNumber, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveSingleCar(response));
                } catch (e) {
                    dispatch(receiveSingleCarError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const fetchLowestPrice = () => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        return fetch(`/api/v3/lowestprice/`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(getLowestPrice(response));
                } catch (e) {
                    dispatch(getLowestPriceError(response))
                }


            })
            .catch(handleNetworkError);
    }
}

export const fetchCategories = () => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        return fetch(`/api/v3/categories/`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(getCategories(response));
                } catch (e) {
                    dispatch(getCategoriesError(response))
                }
            })
            .catch(handleNetworkError);
    }
}