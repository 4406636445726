import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const upsellTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'UPSELL_CARS');


export const requestUpsellCars = () => {
    return {
        type: upsellTypes.REQUEST,
    };
};

export const receiveUpsellCars = (response, query={}) => {
    return {
        type: upsellTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveUpsellCarsError = () => {
    return {
        type: upsellTypes.ERROR,
    };
};

export const fetchUpsellCars = (carNumber, withReserved) => {
    return dispatch => {
        dispatch(requestUpsellCars());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v3/upsellcars/?car=` + carNumber, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveUpsellCars(response));
                } catch (e) {
                    dispatch(receiveUpsellCarsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}