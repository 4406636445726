import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { fetchAllCars, fetchLowestPrice, setSearchParams, removeSearchParams, clearCarFiltering, removeCar, fetchCategories } from "../../api/cars/actions";
import { fetchSpecialCars } from "../../api/specialCars/actions"
import { fetchHomePageInfo } from "../../api/pages/actions";
import { fetchAllSearchParams } from "../../api/searchParams/actions"
import { currencyFormatter } from '../../utils';

import CarSlider from "../../components/car-slider"
import FindACarModal from "../../components/find-a-car-modal"
import DetailedSearchModal from '../detailed-search-modal';
import AutoComplete from '../../components/autocomplete';
import MultiSelect from '../multiselect'
import SearchBar from '../search-bar'

let trans = [
    gettext("Tilboðsbílar")
]
class HomepageBase extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            car: false,
            suv: false,
            jeep: false,
            spec: false,
            searchOpen: false,
            carType: [
                "Fólksbílar",
                "Jeppar",
                "Rafbílar"
            ],
            make: false,
            model: false,
            gas_type: false,
            extras: false,
            gear: false,
            type: false,
            gearType: ["Beinskiptur", "Sjálfskiptur"]
        }
    }
    componentDidMount() {
        this.props.store.dispatch(fetchSpecialCars());
        this.props.store.dispatch(fetchLowestPrice());
        this.props.store.dispatch(fetchHomePageInfo());
        this.props.store.dispatch(fetchAllSearchParams());
        this.props.store.dispatch(fetchCategories());
        this.props.onChooseCar(false);
    }

    onInputChange(e) {
        if (this.props.cars.searchParams.cc.indexOf(e.currentTarget.value) > -1) {
            Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: 'cc', value: e.currentTarget.value })))
                .then(this.setState({
                    [e.currentTarget.value]: false,
                }))
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: 'cc', value: e.currentTarget.value })))
                .then(this.setState({
                    [e.currentTarget.value]: true,
                }))
        }
    }

    clearCarList() {
        this.props.store.dispatch(clearCarFiltering());
        this.props.store.dispatch(removeCar());
    }

    toggleDetailedSearch() {
        this.setState({
            searchOpen: !this.state.searchOpen,
        })
    }

    activateDropdown(type) {
        let state = { ...this.state }
        state[type] = !state[type]
        this.setState(state)
    }

    getPath() {
        return `/is/cat/${this.state.searchPath}`
    }

    search() {
        this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams))
    }

    updateSearchLists() {
        this.props.dispatch(fetchAllSearchParams(this.props.cars.searchParams))
    }

    setSearchPath(value) {
        this.setState({
            searchPath: value,
        })
    }

    toggleSearchOpen() {
        this.setState({
            searchOpen: !this.state.searchOpen
        })
    }

    render() {
        let cars = this.props.cars;
        let specialCars = this.props.specialCars;
        let searchParams = this.props.searchParams;

        return (

            <div>
                {this.props.pages.results.items ?
                    <>
                        <div className="header-image" style={{ backgroundImage: "url(" + this.props.pages.results.items[0].header_image_thumbnail.url + ")" }}>
                            <div className="container header-container h-100">
                                <div className="row h-100">
                                    <div className="col-12">
                                        <div className="header-information">
                                            <div className="title">{this.props.pages.results.items[0].header_title}</div>
                                            {this.props.cars.lowestPrice.price_winter !== "" ?
                                                <div className={this.props.cars.lowestPrice.price_36m !== "" ? "subtitle pb-0" : "subtitle"}>
                                                    {this.props.pages.results.items[0].subheader}
                                                </div>
                                            : null}

                                            {/* {this.props.cars.lowestPrice.price_36m !== "" ?
                                                // <div className={this.props.cars.lowestPrice.price_winter !== "" ? "subtitle pt-0" : "subtitle"}>
                                                //     {gettext('Langtímaleiga frá')} <span>{currencyFormatter(this.props.cars.lowestPrice.price_36m)}</span> {gettext('á mánuði')}
                                                // </div>
                                                <div className="subtitle">
                                                    {gettext('Langtímaleiga frá')} <span>{currencyFormatter(this.props.cars.lowestPrice.price_36m)}</span> {gettext('á mánuði')}
                                                </div>
                                            : null} */}
                                            {this.props.pages.results.items[0].pros_list !== "" && <h4 className="pros_text" dangerouslySetInnerHTML={{ __html: this.props.pages.results.items[0].pros_list.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></h4>}
                                            {/* <a className="btn btn-primary btn-caps d-none d-md-block" href="#" data-toggle="modal" data-target={"#LongTermPros"}>Kostir langtímaleigu</a> */}
                                        </div>
                                        {!this.state.searchOpen ?
                                            <div className="car-chooser d-none d-lg-block">
                                                <div className="title">{gettext('Finndu bíl sem hentar þér')}</div>
                                                <div className="checkbox-container row">
                                                    {this.props.cars && this.props.cars.categories.map(category => 
                                                        <div className="col-6 wrapper">
                                                            <Link to={`cat/${category.slug}`} onClick={this.clearCarList.bind(this)} >
                                                                <div className="chooser">
                                                                    <h4 className="car-name">{category.name}</h4>
                                                                    <img className="card-img img-fluid" src={category.image} ></img>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="btn-wrapper row justify-content-center">
                                                    <div className="btn btn-primary detailed-search d-none d-lg-block" onClick={this.toggleDetailedSearch.bind(this)}>{gettext('Ítarleg leit')}
                                                        <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                                                            <path fillRule="nonzero" d="M6 0C2.693 0 0 2.693 0 6c0 3.306 2.693 6 6 6a5.963 5.963 0 0 0 3.793-1.357l3.181 3.181a.602.602 0 0 0 .85 0 .594.594 0 0 0 0-.843l-3.18-3.182A5.989 5.989 0 0 0 11.998 6C12 2.694 9.306 0 6 0zm0 1c2.769 0 5 2.231 5 5s-2.231 5-5 5a4.99 4.99 0 0 1-5-5c0-2.769 2.231-5 5-5z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="car-chooser search-container d-none d-lg-block">
                                                <div className="row extensive-search">
                                                    <h3 className="col-12 pb-4 title">{gettext('Ítarleg leit')}</h3>
                                                    <button className="close" type="button" onClick={this.toggleDetailedSearch.bind(this)}>&times;</button>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Flokkur')}</h6>
                                                        <AutoComplete
                                                            searchItems={searchParams.results.car_types}
                                                            store={this.props.store}
                                                            type="cc"
                                                            shouldFetchAllCars={false}
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            setSearchPath={this.setSearchPath.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                    <div className="col-6"></div>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Tegund')}</h6>
                                                        <AutoComplete
                                                            searchItems={searchParams.results.car_makes}
                                                            store={this.props.store}
                                                            type="make"
                                                            shouldFetchAllCars={false}
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Gerð')}</h6>
                                                        <AutoComplete
                                                            searchItems={searchParams.results.car_models}
                                                            store={this.props.store}
                                                            type="model"
                                                            shouldFetchAllCars={false}
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Gírskipting')}</h6>
                                                        <AutoComplete
                                                            searchItems={searchParams.results.gears}
                                                            store={this.props.store}
                                                            type="gear"
                                                            shouldFetchAllCars={false}
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Eldsneyti')}</h6>
                                                        <AutoComplete
                                                            searchItems={searchParams.results.gas_types}
                                                            store={this.props.store}
                                                            type="gas_type"
                                                            shouldFetchAllCars={false}
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                    <div className="col-6 dropdown-col">
                                                        <h6>{gettext('Aukahlutir')}</h6>
                                                        <MultiSelect
                                                            searchItems={searchParams.results.extras}
                                                            store={this.props.store}
                                                            type="extras"
                                                            updateSearchLists={this.updateSearchLists.bind(this)}
                                                            fromHomepage={true}
                                                        />
                                                    </div>
                                                </div>
                                                <Link to={{ pathname: this.getPath(), fromHomepageSearch: true }} className={this.getPath() === "" ? "search-btn disabled" : "search-btn"} onClick={this.search.bind(this)}><span>Leita</span></Link>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="car-chooser-popup d-lg-none" data-toggle="modal" data-target={"#FindACar"}>
                                <h3 className="title">{gettext('Finndu bíl sem hentar þér')}</h3>
                            </div>
                        </div> </> : null}
                <div className="container car-slider">
                    <h3 className="car-detail-title">{gettext('Vel valdir bílar á sérkjörum')}</h3>
                    {specialCars.isFetching ?
                        <div className="spinner"></div> :
                        <CarSlider
                            cars={specialCars}
                            store={this.props.store}
                            onChooseCar={this.props.onChooseCar.bind(this)}
                            specialCars={true}
                            lang={this.props.lang}
                        />}
                </div>
                {this.props.cars && this.props.cars.categories ?
                    <FindACarModal
                        id="FindACar"
                        clearCarList={this.clearCarList.bind(this)}
                        categories = {this.props.cars.categories}
                        lang={this.props.lang}
                    />
                    : null}
                {cars.isFetching || searchParams.results.length === 0 ? null :
                    <DetailedSearchModal id="DetailedSearch"
                        cars={cars}
                        carType={this.state.carType}
                        activateDropdown={this.activateDropdown.bind(this)}
                        gearType={this.state.gearType}
                        store={this.props.store}
                        searchParams={searchParams}
                        setSearchPath={this.setSearchPath.bind(this)}
                        updateSearchLists={this.updateSearchLists.bind(this)}
                        search={this.search.bind(this)}
                        getPath={this.getPath.bind(this)}
                        homepage={true}
                    />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
        specialCars: {},
        pages: {},
        searchParams: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars
    f.specialCars = (state.specialCars === undefined) ? {} : state.specialCars
    f.pages = (state.pages === undefined) ? {} : state.pages
    f.searchParams = (state.searchParams === undefined) ? {} : state.searchParams
    return f;
}

export default connect(mapStateToProps)(HomepageBase);