import React from 'react';
import Slider from "react-slick";
import { getColorIS } from '../../utils';

class MyCarSlider extends React.Component {

    constructor(props) {
        super(props)
    }

    getCar(car, i) {
        return (
            <div className="card-wrapper p-0" id={car.fastanumer}>
                <div className="card card-car">
                    <div className="card-body">
                        <div className="card-information">
                            <div className="card-title">
                                <h3>{car.lysing}</h3>
                            </div>
                            <div className="description">
                                <div className="year">
                                    <span>{gettext('Árgerð')}</span>
                                    <span>{`20` + car.model_year}</span>
                                </div>
                            </div>
                            <img className="card-img img-fluid" src={car.car_image}></img>
                        </div>
                        <div className="white-bottom"></div>
                    </div>
                </div>
            </div>
        )
    }

    getAllCars(cars) {
        if (cars && cars.results) {
            let allCars = cars.results.map((car, i) =>
                this.getCar(car, i + 1)
            )
            return allCars;
        }

    }

    afterChangeHandler(currentSlide) {
        this.props.updateCurrentSlide(currentSlide);
    }


    render() {
        let cars = this.props.cars;
        var settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow:
                <button>
                    <svg className="icon-prev" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16.8 12.8">
                        <path fill="#D2072F" d="M4.8 0l.8.6-4 5.3h15.2v1H1.6l4 5.3-.8.6L0 6.4z" />
                    </svg>
                </button>,
            nextArrow:
                <button>
                    <svg className="icon-next" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 16.8 12.8">
                        <path fill="#D2072F" d="M12 0l-.8.6 4 5.3H0v1h15.1l-3.9 5.3.8.6 4.8-6.4z" />
                    </svg>
                </button>,
        };
        return (
            cars ?
                <Slider {...settings} className="col-12 col-lg-8 car-card p-0" afterChange={this.afterChangeHandler.bind(this)}>
                    {this.getAllCars(cars)}
                </Slider> : <div>{gettext('Engir bílar hér!')}</div>
        )
    }
}


MyCarSlider.defaultProps = {
}


export default MyCarSlider;