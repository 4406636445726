import React from "react";
import { connect } from 'react-redux'

class Demo extends React.Component {
    constructor(props) {
        super(props)
    }

    
    render() {
        return (<>Demo!</>)
    }
}

// function mapStateToProps(state) {

//     var f = {
//         events: {},
//     }
//     f.events = (state.events === undefined) ? {} : state.events
//     return f;
// }

// export default connect(mapStateToProps)(Demo);

export default Demo