import React from 'react';
import { Redirect } from 'react-router-dom';

class Step5 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    earlyPickup(e) {
        this.props.earlyPickup();
        $('.early-pickup-wrapper').html('<div class="thank-you">Takk fyrir, haft verður samband.</div>');
    }

    render() {
        if (this.props.currentStep !== 5) {
            return null
        }
        return (
            <>
                <Redirect to='/order-car/confirmation' />
                <div className="chooser-container">
                    {this.props.pages.results && this.props.pages.results.items.length > 0 && <div className="col-12" dangerouslySetInnerHTML={{ __html: this.props.pages.results.items[0].delivery_date_text.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>}
                    <div className="receipt">
                        <div className="pickup">
                            <div className="title">Afhendingartími</div>
                            <div className="info">Afhending fer fram 10-15 virkum dögum frá pöntun.</div>
                        </div>
                    </div>
                    <div className="early-pickup-wrapper">
                        <input id="early-pickup" type="checkbox" onChange={this.earlyPickup.bind(this)} /><span className="checkmark"></span><label htmlFor="early-pickup">Þessi dagsetning hentar ekki. Ég vil að haft verði samband og finna aðra dagsetningu.</label>
                    </div>
                </div>
            </>
        )
    }
}

Step5.defaultProps = {
}


export default Step5;