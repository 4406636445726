import React from 'react'
import ReactDOM from 'react-dom'
import Demo from './demo'
import { store } from '../../store' 

$.fn.demo = function () {
  return this.each(function () {
    var options = $(this).data();
    options.store = store;
    ReactDOM.render(React.createElement(Demo, options), this);
  });
};


/**
 * Initialize Data Attribute
 */

$(document).ready(function () {
  $('[data-demo]').demo();
});