import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const searchParamsTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SEARCH_PARAMS');

export const requestSearchParams = () => {
    return {
        type: searchParamsTypes.REQUEST,
    };
};

export const receiveSearchParams = (response, query = {}) => {
    return {
        type: searchParamsTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveSearchParamsError = () => {
    return {
        type: searchParamsTypes.ERROR,
    };
};

export const fetchAllSearchParams = (params = {}) => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        dispatch(requestSearchParams());

        return fetch(`/api/v3/searchparams/?${$.param(params)}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveSearchParams(response));
                } catch (e) {
                    dispatch(receiveSearchParamsError(response))
                }


            })
            .catch(handleNetworkError);
    }
}