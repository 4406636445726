import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { cars } from './api/cars/reducers'
import { specialCars } from './api/specialCars/reducers'
import { members } from './api/members/reducers'
import { pages } from './api/pages/reducers'
import { login } from './api/login/reducers'
import { reserveCar } from './api/reserveCar/reducers'
import { upsellCars } from './api/upsell/reducers'
import { searchParams } from './api/searchParams/reducers'
import { checkout } from './api/checkout/reducers'
import { minmax } from './api/minmax/reducers'

import { composeWithDevTools } from 'redux-devtools-extension';


const loggerMiddleware = createLogger()

const reducers = combineReducers({
    cars,
    specialCars,
    members,
    pages,
    login,
    reserveCar,
    upsellCars,
    searchParams,
    checkout,
    minmax
})


let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        thunkMiddleware,
        
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    ))
}

export const store = createStore(reducers, middleware)
