import React from 'react';
import { currencyFormatter } from '../../utils';
import { connect } from 'react-redux';
import { memberLogin, saveVisitor } from '../../api/login/actions';

class Step2 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            phoneno: '',
            email: '',
            loggedIn: false,
            errorMsg: '',
            personuverndApprove: false,
        }
    }

    loggedInUser(name) {
        $('#my-pages-button span').text(name);
        $('#logout-button span').text(gettext('Skrá út'));
        $('#logout-button').attr("href", "/minarsidur/utskraning?next=/");
        $('#logout-button svg').hide();
    }

    componentDidMount(){
        $('.phone-input').parsley();
        let that = this;
        $('.phone-input-wrapper .phone-input').on('input', function() {
            if (that.state.errorMsg !== "") {
                that.setState({
                    errorMsg: ""
                })
            }
        });
    }
    componentDidUpdate(prevProps) {
        if (this.props.login !== prevProps.login && this.props.login.results.status === "ok") {
            this.setState({
                loggedIn: true
            })
            this.loggedInUser(this.props.login.results.nafn);
        } else if (this.props.login !== prevProps.login && !this.props.login.isFetching && this.props.login.results.status === "error") {
            this.setState({
                errorMsg: this.props.login.results.errors[0],
                isFetching: false,
            })
        }
    }

    getUserInfo() {
        this.setState({
            errorMsg: ""
        })
        this.props.addPhoneNo(this.state.phoneno);
        this.props.store.dispatch(memberLogin(this.state.phoneno));
    }
    
    continueToCreditInfoStep() {
        if (this.state.email !== "") {
            this.props.addEmail(this.state.email)
        }
        this.props.nextStep();
        let car = this.props.car;
        let car_object = {
            bilnumer: car.car_number,
            make: car.car_type_make,
            model: car.car_type_model,
            model_type: car.car_type_model_type,
            group_code: car.group_code,
            argerd: car.model_year,
            simi: this.state.phoneno,
        }
        this.props.dispatch(saveVisitor(car_object, this.props.login.results, this.state.phoneno, this.state.email))
    }

    onPhoneNoChange(e) {
        this.setState({
            phoneno: e.currentTarget.value
        })
    }

    onEmailChange(e) {
        this.setState({
            email: e.currentTarget.value
        })
    }

    isEmailValid(){
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isEmailValid = re.test(String(this.state.email).toLowerCase());
        return isEmailValid
    }

    onKeyPress(e){
        if (e.keyCode == 13) {
            e.stopPropagation();
            e.preventDefault();
            this.getUserInfo();
        }
    }

    onEnterHit(e){
        if (e.keyCode == 13) {
            if (this.isEmailValid()) {
                this.continueToCreditInfoStep();
            }
        }
    }

    toggleApprove(e) {
        this.setState({
            [e.currentTarget.id]: !this.state[e.currentTarget.id]
        })
    }

    render() {
        if (this.props.currentStep !== 2 || (!this.props.members.isFetching && this.props.members.results.length > 0) ) {
            return null
        }
        return (
            <div className="row justify-content-center login-page">
                <form className="col-11 col-md-9 col-lg-8 login-form"
                    id="taktikal-form">
                    <div className="row login-form">
                        <h3 className="col-12 login-text">{gettext('Auðkenning')}<br />{gettext('með rafrænum skilríkjum')}</h3>
                        <div className="col-12 phone-input-wrapper">
                            <span className="description">{gettext('Símanúmer')}</span>
                            <input autoFocus className="phone-input" type="number" name="phoneno" placeholder={gettext('Símanúmer')}
                                id="id_phoneno" value={this.state.phoneno} onChange={this.onPhoneNoChange.bind(this)} required="" data-parsley-trigger="keyup"
                                data-parsley-minlength="7" data-parsley-maxlength="7" onKeyDown={this.onKeyPress.bind(this)} />
                            <svg className="mobile-icon" xmlns="http://www.w3.org/2000/svg" id="mobile-icon" viewBox="0 0 15 27">
                                <path id="Combined-Shape"
                                    d="M3 0h9c1.7 0 3 1.3 3 3v21c0 1.7-1.3 3-3 3H3c-1.7 0-3-1.3-3-3V3c0-1.7 1.3-3 3-3zM1 5v19c0 1.1.9 2 2 2h9c1.1 0 2-.9 2-2V5H1zm2-4c-1.1 0-2 .9-2 2v1h13V3c0-1.1-.9-2-2-2H3z"
                                    className="st0" />
                                <circle id="Oval" cx="7.8" cy="23.8" r="1.2" className="st0" />
                                <path id="Line_1_" d="M6 2.5h3" />
                                <path d="M9 2.9H6c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h3c.2 0 .4.2.4.4s-.2.4-.4.4z" />
                            </svg>
                        </div>
                        {this.state.errorMsg != "" ? <div className="col-12 error-message"><div className="warning">{this.state.errorMsg}</div></div> : null}
                        {!this.state.loggedIn ?
                            <div className="col-12 text-center login-button">
                                <div className={this.state.phoneno.length === 7 ? "btn btn-primary btn-red" : "btn btn-primary btn-red disabled"}
                                    onClick={this.getUserInfo.bind(this)}><span>{this.props.login.isFetching ? gettext('Hinkrið...') : gettext('Halda áfram')}</span>
                                </div>
                            </div> : null}
                        {this.state.loggedIn ?
                            <>
                                <div className="col-12 email-input-wrapper">
                                    <span className="description">{gettext('Netfang')}</span>
                                    <input autoFocus={this.state.loggedIn ? true : false} className="email-input" type="email" name="email" placeholder={gettext('Skráðu netfang')}
                                        id="id_email" value={this.state.email} onChange={this.onEmailChange.bind(this)} onKeyDown={this.onEnterHit.bind(this)}/>
                                    <svg className="email-icon" xmlns="http://www.w3.org/2000/svg" width="19" height="20">
                                        <path fill="#121212" fillRule="nonzero" d="M12.425 5.332h1.16v6.756c0 1.155.363 1.77 1.354 1.77 1.62 0 2.804-1.819 2.804-4.644 0-4.669-3.36-7.985-8.122-7.985-4.931 0-8.388 3.562-8.388 8.648 0 5.209 3.457 8.87 8.388 8.87 1.91 0 4.133-.516 5.511-1.425l.532 1.106C14.141 19.435 11.676 20 9.621 20 4.013 20 0 15.75 0 9.877 0 4.127 4.013 0 9.62 0 15.085 0 19 3.808 19 9.214c0 3.513-1.668 5.921-4.061 5.921-1.595 0-2.442-.958-2.514-2.53-.701 1.424-1.934 2.235-3.457 2.235-2.465 0-4.23-1.99-4.23-4.865 0-2.85 1.765-4.84 4.23-4.84 1.571 0 2.78.86 3.457 2.285V5.332zm-3.19 8.329c1.836 0 3.093-1.376 3.093-3.686s-1.257-3.66-3.094-3.66c-1.91 0-3.263 1.498-3.263 3.66 0 2.163 1.353 3.686 3.263 3.686z"/>
                                    </svg>

                                </div>
                                <input id="personuverndApprove" type="checkbox" onChange={this.toggleApprove.bind(this)} /><span className="checkmark"></span><label htmlFor="leiguskilmalarApprove">{gettext('Ég samþykki')} <a href="/skilmalar" target="_blank">{gettext('leiguskilmála AVIS')}</a> {gettext('og')} <a href="/personuverndar-skilmalar" target="_blank">{gettext('persónuverndar skilmála.')}</a></label>
                                <div className="col-12 text-center login-button">
                                    <div className={this.isEmailValid() && this.state.personuverndApprove ? "btn btn-primary btn-red" : "btn btn-primary btn-red disabled"}
                                        onClick={this.continueToCreditInfoStep.bind(this)}><span>{gettext('Halda áfram')}</span></div>
                                </div>
                            </> : null
                        }
                    </div>
                </form>
            </div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        login: {},
    }
    f.login = (state.login === undefined) ? {} : state.login
    return f;
}

export default connect(mapStateToProps)(Step2);