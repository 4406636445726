import React from "react";
import { connect } from 'react-redux'
import { fetchAllCars, setSearchParams, removeSearchParams, clearCarFiltering, fetchCategories } from "../../api/cars/actions";
import { fetchAllSearchParams } from "../../api/searchParams/actions"
import AutoComplete from '../../components/autocomplete';
import MultiSelect from '../../components/multiselect';
import { Link } from 'react-router-dom';

let trans = [
    gettext("Loka leit"),
    gettext("Ítarleg leit")
]
class SearchBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            carTypes: {
                car: window.location.pathname.includes('/folksbilar'),
                jeep: window.location.pathname.includes('/jeppar'),
                spec: window.location.pathname.includes('/rafbilar'),
                offer: window.location.pathname.includes('/tilbodsbilar'),
            },
            price: [this.props.cars.searchParams.pf ? this.props.cars.searchParams.pf : 49900, this.props.cars.searchParams.pt ? this.props.cars.searchParams.pt : 69900],
            gasTypes: {
                G: false,
                D: false,
                E: false,
            },
            make: false,
            model: false,
            gas_type: false,
            extras: false,
            gear: false,
            automatic: false,
            gearType: [gettext('Beinskiptur'), gettext('Sjálfskiptur')],
            makeVal: this.props.cars.searchParams.make ? this.props.cars.searchParams.make : "",
            modelVal: this.props.cars.searchParams.model ? this.props.cars.searchParams.model : "",
        }
    }

    onChange(event) {
        if (this.state.automatic) {
            Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: event.target.id, value: 'auto' })))
                .then(this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams)))
        } else {
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: event.target.id, value: 'auto' })))
                .then(this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams)))
        }
        this.setState({
            automatic: !this.state.automatic
        })
    };

    updateSearchLists(type, value) {
        Promise.resolve(this.props.dispatch(fetchAllSearchParams(this.props.cars.searchParams)))
            .then(() => {
                if (this.props.searchParams.results.car_models.length == 1) {
                    this.setState({
                        modelVal : this.props.searchParams.results.car_models[0]
                    })
                }
            })
        if (type == "make" || type == "model") {
            this.setState({
                [type+'Val']: value,
            })
        }
        if (type == "model") {
            this.setState({
                makeVal: this.props.cars.searchParams.make,
            })
        }
        if (type == "make") {
            this.setState({
                modelVal: "",
            })
        }
    }


    handleCarTypeClick(e) {
        let state = { ...this.state }
        if (state.carTypes[e.target.name] === true) {
            return 
        } else {
            state.carTypes = e.target.name
            Promise.resolve(this.props.store.dispatch(setSearchParams({ key: 'cc', value: e.target.name })))
            this.props.filtersHaveChanged(false);
            this.setState(state);
        }
        this.setState({
            modelVal: "",
            makeVal: "",
        })
    }

    handleSearchClick() {
        this.props.toggleSearchOpen();
    }

    removeExtra(e) {
        Promise.resolve(this.props.store.dispatch(removeSearchParams({ key: 'extras', value: e.target.name })))
            .then(this.props.store.dispatch(fetchAllCars(this.props.cars.searchParams)))
    }

    activateDropdown(type) {
        let state = { ...this.state }
        state[type] = !state[type]
        this.setState(state)
    }

    search() {
        this.props.store.dispatch(removeSearchParams({ key: 'pf', value: this.props.cars.searchParams.pf}));
        this.props.store.dispatch(removeSearchParams({ key: 'pt', value: this.props.cars.searchParams.pt}))
        this.props.search();
        this.props.filtersHaveChanged(false);
        this.props.updatePriceBar();
    }
    onClickClearBtn(e) {
        let type = e.currentTarget.id;
        this.clearFilter(type);
    }

    clearFilter(type){
        this.props.filtersHaveChanged(true);
        Promise.resolve(this.props.store.dispatch(setSearchParams({ key: type, value: ''})))
        .then(() => {
            if(type == "make") {
                this.props.store.dispatch(setSearchParams({ key: 'model', value: ''}))
                this.props.store.dispatch(setSearchParams({ key: 'gt', value: ''}))
                this.props.store.dispatch(setSearchParams({ key: 'extras', value: []}))
            } else if (type == "model") {
                this.props.store.dispatch(setSearchParams({ key: 'gt', value: ''}))
                this.props.store.dispatch(setSearchParams({ key: 'extras', value: []}))
            }
        })
        .then(() => {
            setTimeout(() => {
                this.props.store.dispatch(fetchAllSearchParams(this.props.cars.searchParams))
            }, 200)
    })
        .then(this.setState({
            [type+"Val"]: ""
        }))
        if (type == "make") {
            this.setState({
                modelVal: "",
            })
        }
    }

    componentDidMount() {
        this.props.store.dispatch(fetchCategories());
    }

    render() {
        let cars = this.props.cars;
        const Slider = require('rc-slider');
        const createSliderWithTooltip = Slider.createSliderWithTooltip;
        const Range = createSliderWithTooltip(Slider.Range);

        if (cars && cars.categories && (window.location.hash === "" || window.location.hash === "#/" || !this.props.carChosen)) {
            return (
                <>
                        <div className="container-fluid search-container d-none d-lg-flex">
                            <div className="container">
                                <div className="row normal-search">
                                    <div className="col-12 col-md-6 car-type-wrapper">
                                        {this.props.cars.categories.map(category => 
                                            <Link to={`/cat/${category.slug}`} className={this.state.carTypes === category.slug ? "car-type active" : "car-type" } name={category.slug} onClick={this.handleCarTypeClick.bind(this)}>{category.name}</Link>
                                        )}
                                    </div>
                                    <div className="col-6 col-md-3">
                                    </div>
                                    <div className="col-6 col-md-3 search-button">
                                        <button className="btn btn-primary detailed-search-btn" onClick={this.handleSearchClick.bind(this)}>
                                            {!this.props.searchOpen ? gettext("Ítarleg leit") : gettext("Loka leit")}
                                            {!this.props.searchOpen ?
                                                <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20">
                                                    <path fillRule="nonzero"
                                                        d="M6 0C2.693 0 0 2.693 0 6c0 3.306 2.693 6 6 6a5.963 5.963 0 0 0 3.793-1.357l3.181 3.181a.602.602 0 0 0 .85 0 .594.594 0 0 0 0-.843l-3.18-3.182A5.989 5.989 0 0 0 11.998 6C12 2.694 9.306 0 6 0zm0 1c2.769 0 5 2.231 5 5s-2.231 5-5 5a4.99 4.99 0 0 1-5-5c0-2.769 2.231-5 5-5z" />
                                                </svg> :
                                                <span className="close">&times;</span>
                                            }
                                        </button>
                                    </div>
                                </div>
                                {this.props.searchOpen ?
                                    <div className="row extensive-search">
                                        <div className={this.state.make ? "col-4 col-xl dropdown-col active" : "col-4 col-xl dropdown-col"}>
                                            <div className="auto-title">{gettext('Tegund')} <div className={this.state.makeVal == "" ? "clean-btn d-none": "clean-btn"} id="make" onClick={this.onClickClearBtn.bind(this)}><span>&times;</span></div></div>
                                            <AutoComplete
                                                searchItems={this.props.searchParams.results.car_makes}
                                                store={this.props.store}
                                                type="make"
                                                activateDropdown={this.activateDropdown.bind(this)}
                                                updateSearchLists={this.updateSearchLists.bind(this)}
                                                makeVal={this.state.makeVal}
                                                clearFilter={this.clearFilter.bind(this)}
                                                setSearchPath={this.props.setSearchPath}
                                                filtersHaveChanged={this.props.filtersHaveChanged}
                                            />
                                        </div>
                                        <div className={this.state.model ? "col-4 col-xl dropdown-col active" : "col-4 col-xl dropdown-col"}>
                                            <div className="auto-title">{gettext('Gerð')} <div className={this.state.modelVal == "" ? "clean-btn d-none": "clean-btn"} id="model" onClick={this.onClickClearBtn.bind(this)}><span>&times;</span></div></div>
                                            <AutoComplete
                                                searchItems={this.props.searchParams.results.car_models}
                                                store={this.props.store}
                                                type="model"
                                                activateDropdown={this.activateDropdown.bind(this)}
                                                updateSearchLists={this.updateSearchLists.bind(this)}
                                                modelVal={this.state.modelVal}
                                                clearFilter={this.clearFilter.bind(this)}
                                                setSearchPath={this.props.setSearchPath}
                                                filtersHaveChanged={this.props.filtersHaveChanged}
                                            />
                                        </div>
                                        <div className={this.state.gear ? "col-4 col-xl dropdown-col active" : "col-4 col-xl dropdown-col"}>
                                            <div className="auto-title">{gettext('Gírskipting')}</div>
                                            <AutoComplete
                                                searchItems={this.props.searchParams.results.gears}
                                                store={this.props.store}
                                                type="gear"
                                                activateDropdown={this.activateDropdown.bind(this)}
                                                updateSearchLists={this.updateSearchLists.bind(this)}
                                                setSearchPath={this.props.setSearchPath}
                                                filtersHaveChanged={this.props.filtersHaveChanged}
                                            />
                                        </div>
                                        <div className={this.state.gas_type ? "col-4 col-xl dropdown-col active" : "col-4 col-xl dropdown-col"}>
                                            <div className="auto-title">{gettext('Eldsneyti')}</div>
                                            <AutoComplete
                                                searchItems={this.props.searchParams.results.gas_types}
                                                store={this.props.store}
                                                type="gt"
                                                activateDropdown={this.activateDropdown.bind(this)}
                                                updateSearchLists={this.updateSearchLists.bind(this)}
                                                setSearchPath={this.props.setSearchPath}
                                                filtersHaveChanged={this.props.filtersHaveChanged}
                                            />
                                        </div>
                                        <div className="col-4 col-xl dropdown-col">
                                            <div className="auto-title">{gettext('Aukahlutir')}</div>
                                            <MultiSelect
                                                searchItems={this.props.searchParams.results.extras}
                                                store={this.props.store}
                                                type="extras"
                                                activateDropdown={this.activateDropdown.bind(this)}
                                                updateSearchLists={this.updateSearchLists.bind(this)}
                                                filtersHaveChanged={this.props.filtersHaveChanged}
                                            />
                                        </div>
                                        <div className="col">
                                            <div className="empty-title"></div>
                                            <div className="search-btn" onClick={this.search.bind(this)}>{gettext('Leita')}</div>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>

                </>
            )
        } else {
            return null
        }

    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
        searchParams: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars
    f.searchParams = (state.searchParams === undefined) ? {} : state.searchParams
    return f;
}

export default connect(mapStateToProps)(SearchBar);