import React from "react";
import { connect } from 'react-redux'

class OrderCar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {


        return (
            <div>{gettext('Panta bíl')}</div>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars
    return f;
}

export default connect(mapStateToProps)(OrderCar);