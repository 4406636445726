import React from 'react';
import { Link } from 'react-router-dom';

class FindACarModal extends React.Component {

    constructor(props) {
        super(props)
    }

    clearCarList() {
        this.props.clearCarList();
        $('.modal-backdrop').hide();
        $('body').removeClass('modal-open');
    }

    render() {
        return (
            <div className={this.props.orderCar ? "modal fade aem" : "modal fade aem d-lg-none"} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel"
                aria-hidden="true" id={this.props.id}>
                <div className="modal-dialog modal-bottom">
                    <div className="modal-content">
                        <div className="car-chooser">
                            <h2 className="title">{gettext('Finndu bíl sem hentar þér')}</h2>
                            <div className="checkbox-container row">
                            {this.props.categories.map(category => 
                                <div className="col-6 wrapper">
                                    <Link to={`cat/${category.slug}`} onClick={this.clearCarList.bind(this)} >
                                        <div className="chooser">
                                            <h4 className="car-name">{category.name}</h4>
                                            <img className="card-img img-fluid" src={category.image} ></img>
                                        </div>
                                    </Link>
                                </div>
                            )}
                            </div>
                            {this.props.openFromChosenCar ? null :
                                <div className="btn-wrapper row justify-content-center">
                                    <div className="btn btn-primary detailed-search" data-dismiss data-toggle="modal" data-target={"#DetailedSearch"}>{gettext('Ítarleg leit')}
                                    <svg className="search-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                                            <path fillRule="nonzero" d="M6 0C2.693 0 0 2.693 0 6c0 3.306 2.693 6 6 6a5.963 5.963 0 0 0 3.793-1.357l3.181 3.181a.602.602 0 0 0 .85 0 .594.594 0 0 0 0-.843l-3.18-3.182A5.989 5.989 0 0 0 11.998 6C12 2.694 9.306 0 6 0zm0 1c2.769 0 5 2.231 5 5s-2.231 5-5 5a4.99 4.99 0 0 1-5-5c0-2.769 2.231-5 5-5z" />
                                        </svg>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


FindACarModal.defaultProps = {
}


export default FindACarModal;