import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const reserveCarTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'RESERVE_CAR');

export const requestReserveCar = () => {
    return {
        type: reserveCarTypes.REQUEST,
    };
};

export const receiveReserveCar = (response, query = {}) => {
    return {
        type: reserveCarTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveReserveCarError = () => {
    return {
        type: reserveCarTypes.ERROR,
    };
};


export const reserveCar = (carNumber) => {
    return dispatch => {
        dispatch(requestReserveCar());
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        return fetch(`/api/v3/reservecar/?car=` + carNumber, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveReserveCar(response));
                } catch (e) {
                    dispatch(receiveReserveCarError(response))
                }


            })
            .catch(handleNetworkError);
    }
}