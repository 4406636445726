import React from 'react';
import Slider from "react-slick";
import { currencyFormatter, getCarGroupName, getPath } from '../../utils';
import { Link } from 'react-router-dom';


class UpsellCars extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            contractType: "",
            showAllExtras: false,
        }
    }

    chooseCar(car) {
        this.props.onChooseCar(car, this);
    }

    getGears(gear) {
        if (gear === 'man'){
            return gettext('Beinskiptur')
        } else {
            return gettext('Sjálfskiptur')
        }
    }

    getCar(car, i) {
        let carGroupName = getCarGroupName(car.group_code);
        let pathname = getPath(carGroupName);
        return (
            <div className="card-wrapper" key={i}>
                <Link className="card card-car" car={car} key={car.car_number} to={{ pathname: window.location.pathname === "/" ? pathname : window.location.pathname, hash: `${car.car_number}` }} onClick={this.chooseCar.bind(this, car)}>
                    <div className="card-body">
                        <div className="card-information">
                            <div className="card-title">
                                <div className="group">{car.group_category_name}{car.show_vat_free_logo && " - verð án vsk út árið 2023"}</div>
                                <div>{car.car_type_verbose}</div>
                            </div>
                            <div className="precise-info row">
                                <div className="col-3">
                                    <div className="title">{gettext('Árgerð')}</div>
                                    <div>{`20` + car.model_year}</div>
                                </div>
                                <div className="col-4"> 
                                        <div className="title">{gettext('Skipting')}</div>
                                        <div>{this.getGears(car.gear)}</div>
                                </div>
                                <div className="col-5">
                                    <div className="title">{gettext("Verð frá")}</div>
                                    <div>{currencyFormatter(car.price_min)} {gettext('kr./mán.')}</div>
                                </div>
                            </div>
                            <div className="card-img-wrapper">
                                <img className="card-img img-fluid" src={car.card_image}></img>
                            </div>
                        </div>
                        <div className="card-bottom">
                        </div>
                    </div>
                </Link>
            </div>
        )
    }


    getUpsellCars(cars) {
        if (cars) {
            let allCars = cars.map((car, i) =>
                this.getCar(car, i + 1)
            )
            return allCars;
        }
    }


    render() {
        let upsellCars = this.props.upsellCars;
        var settings = {
            dots: false,
            infinite: false,
            rows: 1,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            focusOnSelect: true,
            mobileFirst: true,
            variableWidth: true,
            placeholders: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        prevArrow: false,
                        nextArrow: false,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ],
            beforeChange(lastSlide, nextSlide) {
                if (nextSlide === 0) {
                    $('.slick-list').addClass('no-left-padding');
                } else {
                    $('.slick-list').removeClass('no-left-padding');
                }
            }
        };

        return (

            <div className="upsell-wrapper">
                <div className="upsell container">
                    <div className="upsell-title">{gettext("Aðrir bílar sem gætu hentað þér")}</div>
                    {this.props.upsellCars && !this.props.upsellCars.isFetching ?
                        <Slider {...settings}>
                            {this.getUpsellCars(upsellCars.results)}
                        </Slider> : null}
                </div>
            </div>
        )
    }
}


UpsellCars.defaultProps = {
}


export default UpsellCars;