import React from 'react';
import { currencyFormatter, getColorIS, getCarGroupName } from '../../utils';
import { savePathname } from "../../api/cars/actions";
import { Link } from 'react-router-dom';

let trans = [
    gettext("Árgerð"),
    gettext("Mánaðarleiga"),
    gettext("kr."),
    gettext("Besti díllinn"),
    gettext("Vetrarleiga"),
    gettext("mánuðir"),
    gettext("Halda áfram"),
    gettext("Sjá minna"),
    gettext("Sjá meira"),
    gettext('UPPSELT')
]

class ChosenCar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            contractType: "",
            showAllExtras: false,
            deltaX: 0,
            velocity: 0,
            timeOfLastDragEvent: 0,
            touchStartX: 0,
            prevTouchX: 0,
            beingTouched: false,
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0)
    }

    saveUrlToHistory(e) {
        this.props.store.dispatch(savePathname(window.location.pathname + window.location.hash));
        this.setState({
            contractType: e.currentTarget.getAttribute('name')
        })
    }

    toggleExtras() {
        this.setState({
            showAllExtras: !this.state.showAllExtras,
        })
    }

    getExtras(extras) {
        if (extras) {
            let allExtras = extras.map((extra, i) => {
                if (this.state.showAllExtras) {
                    return (
                        <div className="col-6 extra">
                            {gettext(extra)}
                        </div>
                    )
                } else {
                    if (i < 6) {
                        return (
                            <div className="col-6 extra">
                                {gettext(extra)}
                            </div>
                        )
                    }
                }
            })
            return allExtras;
        }
    }

    getNextCarID(cars) {
        let nextId = '';
        if (cars.results) {
            cars.results.map((car, i) => {
                var next = cars.results[i + 1]
                if (next && car.car_number === this.props.car.car_number) {
                    nextId = next.car_number
                }
            })
        }
        return (nextId)
    }

    chooseNextCar(cars) {
        let nextCar = '';
        if (cars.results) {
            cars.results.map((car, i) => {
                var next = cars.results[i + 1]
                if (next && car.car_number === this.props.car.car_number) {
                    nextCar = next
                }
            })
            this.props.onChooseCar(nextCar);
        }
    }

    choosePrevCar(cars) {
        let lastCar = '';
        if (cars.results) {
            cars.results.map((car, i) => {
                var last = cars.results[i - 1]
                if (last && car.car_number === this.props.car.car_number) {
                    lastCar = last
                }
            })
            this.props.onChooseCar(lastCar);
        }
    }

    getPrevCarID(cars) {
        let lastId = '';
        if (cars.results) {
            cars.results.map((car, i) => {
                var last = cars.results[i - 1]
                if (last && car.car_number === this.props.car.car_number) {
                    lastId = last.car_number
                }
            })
        }
        return (lastId)
    }

    getLowestPrice(car) {
        const arr = [car.price_winter, car.price_12m, car.price_24m, car.price_36m]
        //const arr = [car.price_12m, car.price_24m, car.price_36m]
        const min = arr.reduce((a, b) => Math.min(a, b))
        return currencyFormatter(min)
    }

    isThisTheLowestPrice(car, comparisionCar) {
        const arr = [car.price_winter, car.price_12m, car.price_24m, car.price_36m]
        //const arr = [car.price_12m, car.price_24m, car.price_36m]
        const min = arr.reduce((a, b) => Math.min(a, b))
        if (min === comparisionCar) {
            return true
        } else {
            return false
        }
    }

    handleStart(clientX) {
        this.setState({
            velocity: 0,
            timeOfLastDragEvent: Date.now(),
            touchStartX: clientX,
            beingTouched: true,
        });
    }

    handleMove(clientX) {
        if (this.state.beingTouched) {
            const touchX = clientX;
            const currTime = Date.now();
            const elapsed = currTime - this.state.timeOfLastDragEvent;
            const velocity = 20 * (touchX - this.state.prevTouchX) / elapsed;
            let deltaX = touchX - this.state.touchStartX;
            this.setState({
                deltaX: deltaX,
                velocity,
                timeOfLastDragEvent: currTime,
                prevTouchX: touchX
            });
        }
    }

    handleEnd() {
        this.setState({
            velocity: this.state.velocity,
            touchStartX: 0,
            beingTouched: false,
        });
        if(this.state.deltaX < -40 && this.getNextCarID(this.props.cars)) {
            this.chooseNextCar(this.props.cars);
            this.setState({
                deltaX: 0,
            })
        } else if (this.state.deltaX > 40 && this.getPrevCarID(this.props.cars)) {
            this.choosePrevCar(this.props.cars);
            this.setState({
                deltaX: 0,
            })
        }
    }

    handleTouchStart(touchStartEvent) {
        touchStartEvent.preventDefault();
        this.handleStart(touchStartEvent.targetTouches[0].clientX);
    }

    handleTouchMove(touchMoveEvent) {
        this.handleMove(touchMoveEvent.targetTouches[0].clientX);
    }

    handleTouchEnd() {
        this.handleEnd();
    }

    handleMouseDown(mouseDownEvent) {
        mouseDownEvent.preventDefault();
        this.handleStart(mouseDownEvent.clientX);
    }

    handleMouseMove(mouseMoveEvent) {
        this.handleMove(mouseMoveEvent.clientX);
    }

    handleMouseUp() {
        this.handleEnd();
    }

    getGears(gear) {
        if (gear === 'man'){
            return gettext('Beinskiptur')
        } else {
            return gettext('Sjálfskiptur')
        }
    }

    render() {
        let car = this.props.car;
        let cars = this.props.cars;

        return (
            <>
                <div className="single-car row d-none d-lg-flex">
                    <div className="card-wrapper car-information col-6" index={car.car_number}>
                        <div className="car-header row m-0">
                            <div className="card-information col-12">
                                <div className="card-title">
                                    <div className="group">{car.group_category_name}{car.show_vat_free_logo && " - verð án vsk út árið 2023"}</div>
                                    <h3>{car.car_type_verbose}</h3>
                                </div>
                                <div className="precise-info row">
                                    <div className="col-3 col-md-2">
                                        <div className="title">{gettext('Árgerð')}</div>
                                        <div>{`20` + car.model_year}</div>
                                    </div>
                                    <div className="col-4"> 
                                        <div className="title">{gettext('Skipting')}</div>
                                        <div>{this.getGears(car.gear)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={this.state.showAllExtras ? "car-body scrollable" : "car-body"}>
                            <div className="checkboxes-wrapper row p-3">

                                { true ? 
                                    <>
                                        <div className="col-12 checkbox-wrapper disabled pl-0 pr-0">
                                            {this.isThisTheLowestPrice(car, car.price_winter) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "winter" ? "price-chooser active" : "price-chooser"} name="winter" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">{gettext('Vetrarleiga')}</div>
                                                <div>{currencyFormatter(car.price_winter)} {gettext('kr./mán.')}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 checkbox-wrapper pl-0">
                                            {this.isThisTheLowestPrice(car, car.price_12m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "12months" ? "price-chooser active" : "price-chooser"} name="12months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">12 {gettext('mánuðir')}</div>
                                                <div>{currencyFormatter(car.price_12m)} {gettext('kr./mán.')}</div>
                                            </div>
                                        </div>
                                        <div className="col-6 checkbox-wrapper pr-0">
                                            {this.isThisTheLowestPrice(car, car.price_24m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "24months" ? "price-chooser active" : "price-chooser"} name="24months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">24 {gettext('mánuðir')}</div>
                                                <div>{currencyFormatter(car.price_24m)} {gettext('kr./mán.')}</div>
                                            </div>
                                        </div>
                                        {/* <div className="col-6 checkbox-wrapper pr-0">
                                            {this.isThisTheLowestPrice(car, car.price_36m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "36months" ? "price-chooser active" : "price-chooser"} name="36months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">36 {gettext('mánuðir')}</div>
                                                <div>{currencyFormatter(car.price_36m)} {gettext('kr./mán.')}</div>
                                            </div>
                                        </div> */}
                                    </>
                                :
                                    <>
                                        <div className="col-12 col-sm-4 checkbox-wrapper disabled pl-0">
                                            {this.isThisTheLowestPrice(car, car.price_36m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "36months" ? "price-chooser active" : "price-chooser"} name="36months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">1 {gettext('mánuður')}</div>
                                                <div>{currencyFormatter(car.price_36m)} {gettext('kr./mán.')}</div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4 checkbox-wrapper pl-0">
                                            {this.isThisTheLowestPrice(car, car.price_12m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "12months" ? "price-chooser active" : "price-chooser"} name="12months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">12 {gettext('mánuðir')}</div>
                                                <div className="price-wrapper">{currencyFormatter(car.price_12m)} <span>{gettext('kr./mán.')}</span></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-4 checkbox-wrapper pl-0">
                                            {this.isThisTheLowestPrice(car, car.price_24m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                            <div className={this.state.contractType === "24months" ? "price-chooser active" : "price-chooser"} name="24months" onClick={this.saveUrlToHistory.bind(this)}>
                                                <div className="contract-name">24 {gettext('mánuðir')}</div>
                                                <div className="price-wrapper">{currencyFormatter(car.price_24m)} <span>{gettext('kr./mán.')}</span></div>
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>
                            <div className="row button-wrapper justify-content-center">
                                <Link className={this.state.contractType === "" ? "btn btn-search disabled" : "btn btn-search"} to={{ pathname: "/order-car", car: car, contractType: this.state.contractType }}><span>{gettext('Halda áfram')}</span></Link>
                            </div>
                        </div>
                        {cars.results ?
                            <div className="btn-wrapper">
                                <Link to={{ pathname: window.location.pathname, hash: `${this.getPrevCarID(cars)}` }} className={this.getPrevCarID(cars) === "" ? "prev-btn hidden" : "prev-btn"} onClick={this.choosePrevCar.bind(this, cars)}>
                                    <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 16.8 12.8">
                                        <path fill="#D2072F" d="M4.8 0l.8.6-4 5.3h15.2v1H1.6l4 5.3-.8.6L0 6.4z" />
                                    </svg>
                                </Link>
                            </div> : null}
                    </div>
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="row justify-content-center">
                                    <div className="col-10 card-img-wrapper">
                                        <img className="card-img" src={car.card_image}></img>
                                    </div>
                                    <div className="col-12 detailed-car-information">
                                        <div className="extras-wrapper row">
                                            {this.getExtras(car.specialties)}
                                        </div>
                                        <div className="show-extras-wrapper row">
                                            <div className={this.state.showAllExtras ? "col-12 title show-extras show-less" : "col-12 title show-extras"} onClick={this.toggleExtras.bind(this)} >{this.state.showAllExtras ? gettext('Sjá minna') : gettext('Sjá meira')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrapper">
                                {cars.results ?
                                    <Link to={{ pathname: window.location.pathname, hash: `${this.getNextCarID(cars)}` }} className={this.getNextCarID(cars) === "" ? "next-btn hidden" : "next-btn"} onClick={this.chooseNextCar.bind(this, cars)}>
                                        <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 16.8 12.8">
                                            <path fill="#D2072F" d="M12 0l-.8.6 4 5.3H0v1h15.1l-3.9 5.3.8.6 4.8-6.4z" />
                                        </svg>

                                    </Link> : null}
                            </div>
                        </div>

                    </div>
                </div>

                <div className="single-car row d-lg-none">
                    <div className="card-wrapper car-information col-12" index={car.car_number}>
                        <div className="car-header row m-0">
                            <div className="card-information col-12">
                                <div className="card-title">
                                    <div className="group">{car.group_category_name}{car.show_vat_free_logo && " - verð án vsk út árið 2023"}</div>
                                    <h3>{car.car_type_verbose}</h3>
                                </div>
                                <div className="precise-info row">
                                    <div className="col-3 col-md-2">
                                        <div className="title">{gettext('Árgerð')}</div>
                                        <div>{`20` + car.model_year}</div>
                                    </div>
                                    <div className="col-6">
                                        <div className="title">{gettext("Besti díllinn")}</div>
                                        <div>frá {this.getLowestPrice(car)} {gettext('kr.')}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 info-img-wrapper"
                        onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
                        onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
                        onTouchEnd={() => this.handleTouchEnd()}
                        // The following event handlers are for mouse compatibility:
                        onMouseDown={mouseDownEvent => this.handleMouseDown(mouseDownEvent)}
                        onMouseMove={mouseMoveEvent => this.handleMouseMove(mouseMoveEvent)}
                        onMouseUp={() => this.handleMouseUp()}>
                        <div className="row justify-content-center">
                            <div className="col-12 card-img-wrapper">
                                <img className="card-img" src={car.card_image}></img>
                            </div>
                            <div className="col-12 detailed-car-information">
                                <div className="extras-wrapper row">
                                    {this.getExtras(car.specialties)}
                                </div>
                                <div className="show-extras-wrapper row">
                                    <div className={this.state.showAllExtras ? "col-12 title show-extras show-less" : "col-12 title show-extras"} onClick={this.toggleExtras.bind(this)} >{this.state.showAllExtras ? gettext('Sjá minna') : gettext('Sjá meira')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={this.state.showAllExtras ? "car-body scrollable" : "car-body"}>
                            <div className="checkboxes-wrapper row p-3">
                                { true ? 
                                <>
                                <div className="col-12 checkbox-wrapper pl-0 pr-0">
                                    {this.isThisTheLowestPrice(car, car.price_winter) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                    <div className={this.state.contractType === "winter" ? "price-chooser active" : "price-chooser"} name="winter" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name">{gettext('Vetrarleiga')}</div>
                                        <div>{currencyFormatter(car.price_winter)} {gettext('kr./mán.')}</div>
                                    </div>
                                </div>
                                <div className="col-6 checkbox-wrapper pl-0">
                                    {this.isThisTheLowestPrice(car, car.price_12m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                    <div className={this.state.contractType === "12months" ? "price-chooser active" : "price-chooser"} name="12months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name">12 {gettext('mánuðir')}</div>
                                        <div>{currencyFormatter(car.price_12m)} {gettext('kr./mán.')}</div>
                                    </div>
                                </div>
                                <div className="col-6 checkbox-wrapper pr-0">
                                    {this.isThisTheLowestPrice(car, car.price_24m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                    <div className={this.state.contractType === "24months" ? "price-chooser active" : "price-chooser"} name="24months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name">24 {gettext('mánuðir')}</div>
                                        <div>{currencyFormatter(car.price_24m)} {gettext('kr./mán.')}</div>
                                    </div>
                                </div>
                                {/* <div className="col-6 col-md-3 checkbox-wrapper pr-0">
                                    {this.isThisTheLowestPrice(car, car.price_36m) ? <div className="best-price">{gettext('Besti díllinn')}</div> : null}
                                    <div className={this.state.contractType === "36months" ? "price-chooser active" : "price-chooser"} name="36months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name">36 {gettext('mánuðir')}</div>
                                        <div>{currencyFormatter(car.price_36m)} {gettext('kr./mán.')}</div>
                                    </div>
                                </div> */}
                                </>
                                :
                                <>
                                <div className="col-12 col-sm-4 checkbox-wrapper pl-0 pr-0 pr-sm-2 pr-lg-0">
                                    {this.isThisTheLowestPrice(car, car.price_12m) ? <div className="best-price">Besti díllinn</div> : null}
                                    <div className={this.state.contractType === "12months" ? "price-chooser active" : "price-chooser"} name="12months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name d-none d-sm-block">12 {gettext('mánuðir')}</div>
                                        <div className="price-wrapper d-none d-sm-block">{currencyFormatter(car.price_12m)} <span>{gettext('kr./mán.')}</span></div>
                                        <div class="row d-sm-none">
                                            <div className="contract-name col-6 text-left">12 {gettext('mánuðir')}</div>
                                            <div className="col-6 text-right">{currencyFormatter(car.price_12m)} <span>{gettext('kr./mán.')}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 checkbox-wrapper pl-0 pr-0 pr-sm-2 pl-sm-2 pl-lg-0">
                                    {this.isThisTheLowestPrice(car, car.price_24m) ? <div className="best-price">Besti díllinn</div> : null}
                                    <div className={this.state.contractType === "24months" ? "price-chooser active" : "price-chooser"} name="24months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name d-none d-sm-block">24 {gettext('mánuðir')}</div>
                                        <div className="price-wrapper d-none d-sm-block">{currencyFormatter(car.price_24m)} <span>{gettext('kr./mán.')}</span></div>
                                        <div class="row d-sm-none">
                                            <div className="contract-name col-6 text-left">24 {gettext('mánuðir')}</div>
                                            <div className="col-6 text-right">{currencyFormatter(car.price_24m)} <span>{gettext('kr./mán.')}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4 checkbox-wrapper pl-0 pr-0 pr-sm-2 pr-lg-0">
                                    {this.isThisTheLowestPrice(car, car.price_36m) ? <div className="best-price">Besti díllinn</div> : null}
                                    <div className={this.state.contractType === "36months" ? "price-chooser active" : "price-chooser"} name="36months" onClick={this.saveUrlToHistory.bind(this)}>
                                        <div className="contract-name d-none d-sm-block">36 {gettext('mánuðir')}</div>
                                        <div className="price-wrapper d-none d-sm-block">{currencyFormatter(car.price_36m)} <span>{gettext('kr./mán.')}</span></div>
                                        <div class="row d-sm-none">
                                            <div className="contract-name col-6 text-left">36 {gettext('mánuðir')}</div>
                                            <div className="col-6 text-right">{currencyFormatter(car.price_36m)} <span>{gettext('kr./mán.')}</span></div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                } 
                            </div>
                            <div className="row button-wrapper justify-content-center">
                                <Link className={this.state.contractType === "" ? "btn btn-search disabled" : "btn btn-search"} to={{ pathname: "/order-car", car: car, contractType: this.state.contractType }}><span>{gettext('Halda áfram')}</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>

            /* <UpsellCars upsellCars={this.props.upsellCars} onChooseCar={this.props.onChooseCar}/> */
        )
    }
}


ChosenCar.defaultProps = {
}


export default ChosenCar;