import React from "react";
import { connect } from 'react-redux'
import HomepageBase from "../../components/homepage-base"
import OrderCar from "../../components/order-car"
import SubCarChooser from "../../components/sub-car-chooser"
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import MyContracts from "../../components/my-contracts";
import MyInformation from "../../components/my-information";
import { fetchMemberInfo } from "../../api/members/actions";

class Homepage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            carChosen: false,
        }
    }

    componentDidMount() {
        this.props.dispatch(fetchMemberInfo())
    }

    onChooseCar(val) {
        this.setState({
            carChosen: val
        })
    }


    render() {
        return (
            <div className={this.state.carChosen ? "car-chosen" : ""}>
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact path={`/order-car`} 
                            render={(props) => (
                                <OrderCar {...props} store={this.props.store} onChooseCar={this.onChooseCar.bind(this)} lang={this.props.lang}/>
                                )} />
                        <Route
                            exact path={`/order-car/confirmation`}
                            render={(props) => (
                                <OrderCar {...props} store={this.props.store} onChooseCar={this.onChooseCar.bind(this)} lang={this.props.lang}/>
                                )} />
                        <Route
                            path={'/search'}
                            render={(props) => (
                                <SubCarChooser {...props} store={this.props.store} carChosen={this.state.carChosen} onChooseCar={this.onChooseCar.bind(this)}  lang={this.props.lang}/>
                                )} />
                        <Route
                            path={'/cat/:cc'}
                            render={(props) => (
                                <SubCarChooser {...props} store={this.props.store} carChosen={this.state.carChosen} onChooseCar={this.onChooseCar.bind(this)}  lang={this.props.lang}/>
                                )} />
                        <Route
                            path={`/${this.props.lang}/search`}
                            render={(props) => (
                                <SubCarChooser {...props} store={this.props.store} carChosen={this.state.carChosen} onChooseCar={this.onChooseCar.bind(this)}  lang={this.props.lang}/>
                                )} />
                        <Route
                            path={`/${this.props.lang}/cat/:cc`}
                            render={(props) => (
                                <SubCarChooser {...props} store={this.props.store} carChosen={this.state.carChosen} onChooseCar={this.onChooseCar.bind(this)}  lang={this.props.lang}/>
                                )} />
                        
                        {this.props.members.results && !this.props.members.isFetching &&
                            <Route
                            path={`/minarsidur/samningar`}
                            render={(props) => (
                                <MyContracts {...props} members={this.props.members} />
                                )}
                                />}
                        {this.props.members.results && !this.props.members.isFetching &&
                            <Route
                            path='/minarsidur/minar-upplysingar'
                            render={(props) => (
                                <MyInformation {...props} member={this.props.members.results} />
                                )}
                                />}
                        <Route
                            path="/:lang/"
                            render={(props) => (
                                <HomepageBase {...props} store={this.props.store} lang={this.props.lang} carChosen={this.state.carChosen} onChooseCar={this.onChooseCar.bind(this)} />
                            )}
                        />
                    </Switch>
                </BrowserRouter>
            </div>


        )
    }
}

function mapStateToProps(state) {

    var f = {
        cars: {},
        pages: {},
        members: {},
    }
    f.cars = (state.cars === undefined) ? {} : state.cars
    f.pages = (state.pages === undefined) ? {} : state.pages
    f.members = (state.members === undefined) ? {} : state.members
    return f;
}

export default connect(mapStateToProps)(Homepage);