import React from 'react';
import { currencyFormatter } from '../../utils';
import { reserveCar } from "../../api/reserveCar/actions";


class Step3 extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            approved: false,
        }
    }

    afterPayment(status, data) {
        console.log(status, data)
    }

    proceedToPaymentStep() {
        this.props.nextStep();
    }

    toggleApprove(e) {
        this.setState({
            approved: e.currentTarget.checked
        })
    }

    render() {
        if (this.props.currentStep !== 3) {
            return null
        }
        let pages = this.props.pages.results;
        // this.props.store.dispatch(reserveCar(this.props.car.car_number));
        return (
            !pages.isFetching ?
                <div className="container chooser-container">
                    <div className="credit-info row m-0">
                        <h2 className="col-12">{gettext('Lánshæfismat')}</h2>
                        {this.props.pages.results && this.props.pages.results.items.length > 0 && <div className="col-10" dangerouslySetInnerHTML={{ __html: this.props.pages.results.items[0].credit_info_text.replace(/(?:\r\n|\r|\n)/g, '<br>') }}></div>}
                        <div className="col-12">
                            <input id="credit-info_approve" type="checkbox" onChange={this.toggleApprove.bind(this)} /><span className="checkmark"></span><label htmlFor="credit-info_approve">{gettext('Ég samþykki uppflettingu á lánshæfismati mínu.')}</label>
                        </div>
                    </div>
                    <div className="row button-wrapper">
                        <div className="col-12 text-center">
                            <div id="car_search_link" className={this.state.approved ? "btn btn-primary btn-caps" : "btn btn-primary btn-caps disabled"} onClick={this.proceedToPaymentStep.bind(this)}><span>{gettext('Halda áfram')}</span></div>
                        </div>
                    </div>
                </div> : null
        )
    }
}

Step3.defaultProps = {
}


export default Step3;