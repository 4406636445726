import React from 'react';

import { reserveCarTypes } from './actions'

const initialState = {
    isFetching: false,
    response: ''
}



export function reserveCar(state = initialState, action) {
    switch (action.type) {

        case reserveCarTypes.REQUEST:
            state = {...state, isFetching: true,}
            state.isFetching = true
            return state

        case reserveCarTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.response = action.data
            return state

        default:
            return state
    }
}