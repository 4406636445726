import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const memberTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'MEMBERSHIP');


export const requestMemberInfo = () => {
    return {
        type: memberTypes.REQUEST,
    };
};

export const receiveMemberInfo = (response, query={}) => {
    return {
        type: memberTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveMemberInfoError = () => {
    return {
        type: memberTypes.ERROR,
    };
};

export const fetchMemberInfo = () => {
    return dispatch => {
        dispatch(requestMemberInfo());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
        

        return fetch(`/api/v3/minarsidur/`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveMemberInfo(response));
                } catch (e) {
                    dispatch(receiveMemberInfoError(response))
                }


            })
            .catch(handleNetworkError);
    }
}