import React from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


class LoggedInMenu extends React.Component {
    constructor(props) {
        super(props)
    }

    shouldDisplayContracts(members) {
        let disp = false;
        if (!members.isFetching && members.results && members.results[0] && members.results[0].member) {
            disp = true
        }
        return disp
    }

    render() {
        return (
            <>
            {this.shouldDisplayContracts(this.props.members) && <li className="nav-item">
                    <a className="nav-link" href={`/minarsidur/samningar`}>
                        {gettext('Samningar')}
                    </a>
                </li>}
                
                {/* <li className="nav-item">
                    <Link className="nav-link" to={`/kvittanir`}>
                    Kvittanir
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to={`/thjonusta`}>
                    Þjónusta
                    </Link>
                </li> */}
                <li className="nav-item">
                    <a className="nav-link" href={`/minarsidur/minar-upplysingar`}>
                        {gettext('Upplýsingar')}
                    </a>
                </li>
            </>
        )
    }
}

function mapStateToProps(state) {

    var f = {
        // apartments: {},
        members: {},
        // deletingApplication: {},
    }
    // f.apartments = (state.apartments === undefined) ? {} : state.apartments
    f.members = (state.members === undefined) ? {} : state.members
    // f.deletingApplication = (state.deletingApplication === undefined) ? {} : state.deletingApplication
    return f;
}

export default connect(mapStateToProps)(LoggedInMenu);
