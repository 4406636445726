import React from 'react';

import { minmaxTypes} from './actions'



const initialState = {
    isFetching: false,
    minMax: ''
}



export function minmax(state = initialState, action) {
    switch (action.type) {
                
        case minmaxTypes.REQUEST:
            state = { ...state, isFetching: true, }
            state.isFetching = true
            return state

        case minmaxTypes.RECEIVE:
            state = { ...state, isFetching: false }
            state.minMax = action.data

            return state

        default:
            return state
    }
}