import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const minmaxTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'MINMAX');

export const requestMinmax = () => {
    return {
        type: minmaxTypes.REQUEST,
    };
};

export const receiveMinmax = (response, query = {}) => {
    return {
        type: minmaxTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveMinmaxError = () => {
    return {
        type: minmaxTypes.ERROR,
    };
};

export const fetchMinMaxPrice = (params = {}) => {

    return dispatch => {
        dispatch(requestMinmax());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',

        }

        let newParamsObj = {};
        Object.keys(params).map(element => {
            if (element === "extras" || element === "gc") {
                newParamsObj[element] = params[element].join()
                
            } else {
                newParamsObj[element] = params[element]
            }
        });

        return fetch(`/api/v3/minmaxprice/?${$.param(newParamsObj)}`, {
            headers: headers
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveMinmax(response));
                } catch (e) {
                    dispatch(receiveMinmaxError(response))
                }


            })
            .catch(handleNetworkError);
    }
}