import { createTypes } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const checkoutTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'CHECKOUT');

export const requestCheckout = () => {
    return {
        type: checkoutTypes.REQUEST,
    };
};

export const receiveCheckout = (response, query = {}) => {
    return {
        type: checkoutTypes.RECEIVE,
        query: query,
        data: response,
    };
};

export const receiveCheckoutError = () => {
    return {
        type: checkoutTypes.ERROR,
    };
};

export const checkout = (token, contract_object) => {
    return dispatch => {
        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': window.csrf_token,
        }
        dispatch(requestCheckout());

        fetch(`/api/v3/checkout/`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({'contract_object': contract_object, 'token': token}),
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveCheckout(response));
                } catch (e) {
                    dispatch(receiveCheckoutError(response))
                }


            })
            .catch(handleNetworkError);
    }
}